<template>
  <div />
</template>

<script>
import { useServicesQueryHandler } from '@/views/apps/service/usePoints'

export default {

  setup() {
    const {
      serviceQueryHandler,
    } = useServicesQueryHandler()

    serviceQueryHandler()
    return {
    }
  },
}
</script>
