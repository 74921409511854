<template>
  <div>
    <referrals-register />
    <referrals-list />
  </div>
</template>

<script>

import ReferralsList from '@/views/apps/referral/referral-list/ReferralList.vue'
import ReferralsRegister from '@/views/apps/referral/referrals/ReferralsRegister.vue'

export default {
  name: 'Referrals',
  components: {
    ReferralsList,
    ReferralsRegister,
  },
}
</script>

<style scoped>

</style>
