<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title v-if="title">
        <div class="d-flex">
          <feather-icon
            icon="CameraIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Media') }}
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          ref="uploads"
          cols="12"
        >
          <!--          <b-form-group-->
          <!--            :label="$t('Main Image')"-->
          <!--            label-for="mainImage"-->
          <!--            class="mb-2"-->
          <!--          >-->
          <!--            <vue-dropzone-->
          <!--              id="mainImage"-->
          <!--              ref="mainImage"-->
          <!--              :options="dropzoneOptionsMainImage()"-->
          <!--              @vdropzone-sending="uploadingMainImage"-->
          <!--              @vdropzone-success="uploadedMainImage"-->
          <!--              @vdropzone-removed-file="removeMainImage"-->
          <!--              @vdropzone-file-added="updateThumbnailSize"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <b-form-group
            :label="$t('Media Files')"
            label-for="mediaFiles"
            class="mb-2"
          >
            <vue-dropzone
              id="mediaFiles"
              ref="mediaFiles"
              :options="dropzoneOptionsMediaFiles()"
              vue-dropzone
              @vdropzone-sending="uploadingMediaFiles"
              @vdropzone-success="uploadedMediaFiles"
              @vdropzone-removed-file="removeMediaFile"
              @vdropzone-file-added="updateThumbnailSize"
              @vdropzone-max-files-reached="uploadLimit=0"
              @vdropzone-files-added="processQueue"
              @vdropzone-queue-complete="queueComplete"
            />
            <b-button
              v-if="uploadLimit"
              variant="outline-primary"
              class="mt-1"
              block
              @click="addMoreFiles"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ this.$t('Add more') }} {{ uploadLimit }}</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueCookie from 'vue-cookie'

import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BFormGroup, BRow, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/mixins/notification/toasts'
import { isMobile } from '@/mixins/functions'
import jwt from '@/auth/jwt/useJwt'
import {useUserUi} from '@/views/apps/user/useUser'
export default {
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    vueDropzone: vue2Dropzone,
  },
  mixins: [showToast, isMobile],
  props: {
    pointData: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      dropzoneCommonOptions: {
        url: `${process.env.VUE_APP_API_BASE_PATH}/images`,
        paramName: 'image',
        maxFilesize: 10,
        acceptedFiles: 'image/*',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        addRemoveLinks: true,
        dictRemoveFile: 'X',
        dictCancelUpload: this.$t('Cancel'),
        resizeWidth: 600,
        dictDefaultMessage: this.$t('message.dict_default_message'),
        dictFallbackMessage: this.$t('message.dict_fallback_message'),
        dictFallbackText: this.$t('message.dict_fallback_text'),
        dictFileTooBig: this.$t('message.dict_file_too_big'),
        dictInvalidFileType: this.$t('message.dict_invalid_file_type'),
        dictResponseError: this.$t('message.dict_response_error'),
        dictUploadCanceled: this.$t('message.dict_upload_canceled'),
        dictCancelUploadConfirmation: this.$t('message.dict_cancel_upload_confirmation'),
        dictRemoveFileConfirmation: this.$t('message.dict_remove_file_confirmation'),
        dictMaxFilesExceeded: this.$t('message.dict_max_files_exceeded'),
      },
      /* Required for solving issue: https://github.com/rowanwins/vue-dropzone/issues/424 */
      is_destroying: false,
      fetchedMediaFiles: [],
      queuedMediaFiles: [],
      mediaFilesThumbnailWidth: null,

      // fetchedMainImage: null,
      // mainImageThumbnailWidth: null,
    }
  },
  computed: {
    uploadLimit: {
      get() {
        const options = this.dropzoneOptionsMediaFiles()
        const mediaFilesQty = this.fetchedMediaFiles.length || this.queuedMediaFiles.length
        return options.maxFiles - mediaFilesQty
      },
      set(value) {
        return value
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'pointData.id': function (val) {
      if (val) {
        this.processQueue()
      }
    },
  },

  mounted() {
    this.defineThumbnailSizes()
    window.addEventListener('resize', event => {
      // this.resizeMainImageThumbnail()
      this.resizeMediaFilesThumbnails()
    })
  },
  beforeDestroy() {
    this.is_destroying = true
  },
  destroyed() {
    this.is_destroying = false
  },
  methods: {
    queueComplete() {
      this.$emit('media-files-uploaded')
    },
    updateDropzoneCommonOptions() {
      this.token = vueCookie.get(jwt.jwtConfig.storageTokenKeyName)
      this.dropzoneCommonOptions.headers.Authorization = `Bearer ${this.token}`
      const userId = this.getManagedUserId()
      if( userId ){
        this.dropzoneCommonOptions.headers['Managed-User-id'] = userId
      }
    },
    dropzoneOptionsMediaFiles() {
      const options = this.dropzoneCommonOptions
      options.maxFiles = 4
      options.autoProcessQueue = false
      options.dictRemoveFileConfirmation = null
      options.dictDefaultMessage = `${this.$t('message.dict_default_message')} (max ${options.maxFiles})`
      return options
    },
    setStyle(e, size) {
      const elem = e
      const imgTag = elem.firstChild
      elem.style.height = `${size}px`
      elem.style.width = `${size}px`
      imgTag.style.height = `${size}px`
      imgTag.style.width = `${size}px`
    },
    resizeMediaFilesThumbnails() {
      const elem = this.$refs.mediaFiles
      if (!elem) return
      this.defineThumbnailSizes()
      const { files } = elem.$el.dropzone
      if (files.length) {
        files.forEach(file => {
          this.setStyle(file.previewElement.children[0], this.mediaFilesThumbnailWidth)
        })
      }
    },
    updateQueuedMediaFiles() {
      if (this.$refs.mediaFiles) {
        this.queuedMediaFiles = this.$refs.mediaFiles.getQueuedFiles()
      }
    },
    processQueue() {
      this.updateQueuedMediaFiles()
      if (!this.queuedMediaFiles.length) {
        this.queueComplete()
      }
      // TODO find more efficient way to update reactive properties (token)
      this.updateDropzoneCommonOptions()
      const pointId = store.getters['point/point'].id
      if (pointId && this.token) {
        this.$refs.mediaFiles.processQueue()
      }
    },
    updateThumbnailSize(file) {
      const size = this.mediaFilesThumbnailWidth
      const elem = file.previewElement.children[0]
      this.updateQueuedMediaFiles()
      // const parent = file.previewElement.parentElement.id
      // if (parent === 'mainImage') {
      //   size = this.mainImageThumbnailWidth
      // }
      this.setStyle(elem, size)
    },
    defineThumbnailSizes() {
      const elem = document.getElementById('mediaFiles')
      if (!elem) return
      const width = elem.clientWidth
      if (width < 502 || isMobile.methods.isMobile()) {
        this.mediaFilesThumbnailWidth = (width - 1) / 2
      } else {
        this.mediaFilesThumbnailWidth = (width - 1) / 4
      }

      // this.mainImageThumbnailWidth = width / 2
    },
    uploadingMediaFiles(file, xhr, formData) {
      if (this.is_destroying) {
        return
      }
      if (this.uploadLimit >= 0) {
        if (!file.id) {
          const pointId = store.getters['point/point'].id
          formData.set('point_id', pointId)
          formData.set('default', false)
        }
      } else {
        this.$refs.mediaFiles.removeFile(file)
      }
    },
    addMoreFiles() {
      this.$refs.mediaFiles.dropzone.hiddenFileInput.click()
    },
    uploadedMediaFiles(file, response) {
      this.fetchedMediaFiles.push(response.data)
      this.showToast({ status: 'success', statusText: 'added' }, 'Media File')
    },
    deleteFile(file) {
      if (this.is_destroying) {
        return
      }
      const data = {}
      data.pointId = store.getters['point/point'].id
      data.imageIds = file.id
      store.dispatch('point/deleteImages', data).then(response => {
        this.showToast(response, 'Media File')
        return response.status === 200
      })
    },
    removeMediaFile(file) {
      const index = this.fetchedMediaFiles.findIndex(f => file.name === f.name)
      if (index >= 0) {
        this.deleteFile(this.fetchedMediaFiles[index])
        this.fetchedMediaFiles.splice(index, 1)
      }
      this.updateQueuedMediaFiles()
      return file.previewElement.remove()
    },
    updateUploadedFiles(point) {
      let files = []
      if (point.media_files !== null && Object.keys(point.media_files).length !== 0) {
        files = point.media_files
        files.forEach(file => {
          this.fetchedMediaFiles.push(file)
          const { url } = file
          this.$refs.mediaFiles.manuallyAddFile(file, url)
          this.updateThumbnailSize(file)
        })
      }
    },
    //* Main Image *//
    // dropzoneOptionsMainImage() {
    //   const options = this.dropzoneCommonOptions
    //   options.maxFiles = 1
    //   options.dictDefaultMessage = this.$t('Drop or Upload here')
    //   return options
    // },

    // resizeMainImageThumbnail() {
    //   if (!this.fetchedMainImage) return
    //   this.defineThumbnailSizes()
    //   this.setStyle(
    //     this.$refs.mainImage.$el.dropzone.previewsContainer.children[1].firstChild.nextElementSibling,
    //     this.mainImageThumbnailWidth,
    //   )
    // },

    // uploadingMainImage(file, xhr, formData) {
    //   const pointId = store.getters['point/point'].id
    //   formData.set('point_id', pointId)
    //   formData.set('default', true)
    // },

    // uploadedMainImage(file, response) {
    //   this.fetchedMainImage = response.data
    //   this.showToast({ status: 'success', statusText: 'added' }, 'Media File')
    // },

    // removeMainImage(file) {
    //   this.deleteFile(file)
    //   file.previewElement.remove()
    //   this.fetchedMainImage = null
    // },

    // updateUploadedMainImage(point) {
    //   const file = point.main_image
    //   if (!file) return
    //   this.fetchedMainImage = file
    //   const { url } = point.main_image
    //   this.$refs.mainImage.manuallyAddFile(file, url)
    //   this.updateThumbnailSize(file)
    // },
  },
  setup() {
    const {getManagedUserId}=useUserUi()
    const token = vueCookie.get(jwt.jwtConfig.storageTokenKeyName)
    return {
      token,
      getManagedUserId
    }
  },

}
</script>
<style lang="scss">
.dropzone .dz-preview .dz-error-message {
  bottom: 0!important;
  padding: 0!important;
  height: min-content!important;
  top: auto;
  border-radius: 0.358rem;
}
.dz-preview .dz-remove{
  text-decoration: none!important;
  bottom: 4px!important;
  border: 1px solid white!important;
  font-weight: inherit!important;;
  opacity: 0;
  font-family: inherit!important;
  font-size: inherit!important;
  width: fit-content!important;
  height: fit-content!important;
  right: 0;
  margin: 0.8rem;
  padding: 0 0.358rem!important;
  border-radius: 0.358rem;
  top: 0!important;
}
.vue-dropzone {
  border: 2px dashed #e5e5e5;
  border-radius: 0.358rem;
}
//#mainImage.vue-dropzone{
//  text-align: center;
//}
.dropzone{
  padding: 0;
  min-height: 0;
 background: transparent;
}
.dropzone .dz-preview {
margin: 0;
}
.dz-image>img{
object-fit:fill!important;
}
.dropzone .dz-preview:hover {
z-index: 2;
}
.dropzone .dz-preview.dz-image-preview {
background: transparent;
}
.dz-filename{
display: none;
}
</style>
