<template>
  <div class="offer-list-simple">
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-row>
        <b-col
          class="text-center pb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            tag="router-link"
            variant="primary"
            :to="{ name: 'apps-service-offer-add'}"
            :disabled="!userCanAddOffer()"
            block
          >
            <feather-icon
              class="mr-50"
              icon="BriefcaseIcon"
            />
            <span class="align-middle">{{ $t('Offer services') }}</span>
          </b-button>
        </b-col>

      </b-row>
      <b-row
        v-if="userCanAddOffer()"
        class="match-height"
      >
        <b-col
          v-for="point in points"
          :key="point.id"
          cols="12"
        >
          <b-card
            border-variant="primary"
            class="shadow-none"
            no-body
          >

            <!--              <b-row>-->
            <!--                <b-col-->
            <!--                  xs="6"-->
            <!--                  class="text-left"-->
            <!--                >-->
            <!--                  <b-dropdown-->
            <!--                    id="dropdown-grouped"-->
            <!--                    variant="link"-->
            <!--                    left-->
            <!--                    class="dropdown-icon-wrapper"-->
            <!--                  >-->
            <!--                    <template #button-content>-->

            <!--                      <feather-icon-->
            <!--                        icon="MoreVerticalIcon"-->
            <!--                        size="18"-->
            <!--                        class="cursor-pointer"-->
            <!--                      />-->
            <!--                    </template>-->
            <!--                    <b-dropdown-item :to="{ name: 'apps-service-offer-info', params: { id: point.id } }">-->
            <!--                      <feather-icon icon="FileTextIcon" />-->
            <!--                      <span class="align-middle ml-50">{{ $t('View') }}</span>-->
            <!--                    </b-dropdown-item>-->
            <!--                    <b-dropdown-item-->
            <!--                      v-if="ability.can('update', point)"-->
            <!--                      :to="{ name: 'apps-service-offer-edit', params: { id: point.id } }"-->
            <!--                    >-->
            <!--                      <feather-icon icon="EditIcon" />-->
            <!--                      <span class="align-middle ml-50">{{ $t('Edit') }}</span>-->
            <!--                    </b-dropdown-item>-->
            <!--                    <b-dropdown-item v-if="ability.can('update', point)">-->
            <!--                      <feather-icon icon="TrashIcon" />-->
            <!--                      <span-->
            <!--                        class="align-middle ml-50"-->
            <!--                        @click.prevent="deleteOffer(point)"-->
            <!--                      >{{ $t('Delete') }}</span>-->
            <!--                    </b-dropdown-item>-->
            <!--                  </b-dropdown>-->
            <!--                </b-col>-->
            <!--                <b-col xs="6">-->
            <!--                  <div class="position-absolute statuses">-->
            <!--                    <b-badge-->
            <!--                      :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"-->
            <!--                    >-->
            <!--                      <small>-->
            <!--                        {{ $t(point.post_status) }}-->
            <!--                        <feather-icon-->
            <!--                          :icon="getPostStatusProperty(point.post_status, 'icon')"-->
            <!--                        />-->
            <!--                      </small>-->
            <!--                    </b-badge>-->
            <!--                  </div>-->
            <!--                </b-col>-->

            <!--              </b-row>-->
            <!--              <b-card-header>-->
            <!--                <b-card-title>-->
            <!--                  <span class="text-uppercase">{{ point.name }}</span>-->
            <!--                </b-card-title>-->
            <!--              </b-card-header>-->
            <!--              <b-card-body>-->
            <!--                <b-card-text>-->
            <!--                  {{ point.description }}-->
            <!--                </b-card-text>-->
            <!--              </b-card-body>-->
            <!--              <b-card-footer>-->
            <!--                <b-badge-->
            <!--                  pill-->
            <!--                  variant="light-primary"-->
            <!--                >-->
            <!--                  {{ getTypeTitle(point.point_type.slug) }}-->
            <!--                </b-badge>-->
            <!--                <b-badge-->
            <!--                  v-for="(purpose,index) in point.point_purposes"-->
            <!--                  :key="index"-->
            <!--                  pill-->
            <!--                  variant="light-secondary"-->
            <!--                >-->
            <!--                  {{ getPurposeTitle(purpose.slug) }}-->
            <!--                </b-badge>-->
            <!--              </b-card-footer>-->

            <offer-details :offer="point" />
            <b-card-footer>
              <b-row>

                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    class="mb-1"
                    :to="{ name: 'apps-service-offer-edit', params: { id: point.id } }"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50 d-none d-sm-inline"
                    />
                    <span class="align-middle">{{ $t('Edit') }}</span>
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    block
                    class="mb-1"
                    @click.prevent="deleteOffer(point)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50 d-none d-sm-inline"
                    />
                    <span class="align-middle">{{ $t('Delete') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardFooter,
  BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import store from '@/store'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { truncate } from '@/filters/input'
import { showToast } from '@/mixins/notification/toasts'
import { serviceOptions } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'
import OfferDetails from '@/views/apps/service/offer/details/OfferDetails.vue'
import { useShopFiltersSortingAndPagination, useServiceRequestsRemoteData } from './usePointList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BButton,
    BCardFooter,
    BOverlay,

    OfferDetails,
  },
  filters: {
    truncate, formatDateTime,
  },

  mixins: [getPostStatusProperty, getUserAvatarText, showToast],

  computed: {
    points() {
      return store.getters['point/offers']
    },
  },
  methods: {
    deleteOffer(point) {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('point/deleteServiceOffer', { id: point.id }).then(response => {
            if ([200, 201, 'success'].includes(response.status)) {
              this.points.splice(this.points.findIndex(item => item.id === point.id), 1)
              this.showToast(response, 'Offer')
            }
          })
        }
      })
    },
  },
  setup() {
    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      sortBy, filters,
    } = useShopFiltersSortingAndPagination()

    const {
      handleEditOfferActionClick, resolvePointTypeBadgeVariant, userCanAddOffer,
    } = usePointsUi()

    const ability = defineAbilityForCurrentUser()

    const {
      fetchPoints, submittingFetch,
    } = useServiceRequestsRemoteData()

    const fetchFilteredPoints = () => {
      const args = {
        sort_by: sortBy.value.value,
        order: filters.value.order,
      }
      fetchPoints(args)
    }
    fetchFilteredPoints()

    return {
      // useShopUi
      handleEditOfferActionClick,
      resolvePointTypeBadgeVariant,

      // useServiceRequestsRemoteData
      submittingFetch,
      ability,
      userCanAddOffer,
      getPurposeTitle,
      getTypeTitle,
    }
  },
}
</script>
<style lang="scss" >

.statuses{
  top: -1px!important;
  right:0;
  margin-right: 1rem!important;
}
.statuses>span{
  border-radius: 0;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}

</style>
