<template>
  <div style="height: inherit">

    <!-- Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-flex d-lg-none mr-1"
                size="22"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex justify-content-start align-items-center">

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=" text-uppercase"
                block
                @click="$router.push({ name: 'apps-service-request-add-wizard'})"
              >
                <span class="d-none d-sm-block">{{ $t('Request services') }}</span>
                <span class="d-sm-none">{{ $t('Add') }}</span>

              </b-button>
              <ServiceQueryShareButton class="ml-1" />
              <!-- Per Page -->
              <!--              <b-col>-->
              <!--                <label>Show</label>-->
              <!--                <v-select-->
              <!--                  v-model="filters.perPage"-->
              <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                  :options="filterOptions.perPage"-->
              <!--                  :clearable="false"-->
              <!--                  class="per-page-selector d-inline-block mx-50"-->
              <!--                />-->
              <!--                <label>entries</label>-->
              <!--              </b-col>-->
              <!-- Sort Button -->
              <!--              <b-dropdown-->
              <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                :text="$t(sortBy.text)"-->
              <!--                right-->
              <!--                variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item-->
              <!--                  v-for="sortOption in sortByOptions"-->
              <!--                  :key="sortOption.value"-->
              <!--                  @click="sortBy=sortOption"-->
              <!--                >-->
              <!--                  {{ $t(sortOption.text) }}-->
              <!--                </b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- TOP Sidebar -->

    <top-filter-sidebar
      :filters="filters"
      :filter-options="filterOptions"
      :total-users="totalUsers"
      :submitting-fetch="submittingFetch"
      fetch
    />
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
    >
      <!-- Users -->
      <section v-if="itemView==='map-view'">
        <b-card
          no-body
          class="my-2 p-1"
        >
          <providers-list-map-view
            :data="users"
            :filters="filters"
          />
        </b-card>
      </section>
      <section
        v-else
        :class="itemView"
      >
        <b-card
          v-for="user in users"
          :key="user.id"
          class="ecommerce-card"
          no-body
        >
          <div class="item-img justify-content-center">
            <ProfileAvatar
              :user="user"
            />
          </div>

          <!-- Details -->
          <b-card-body>
            <div class="d-flex justify-content-between  align-items-center">
              <profile-languages :user="user" />
              <profile-rating />
            </div>

            <b-card-text class="item-description">
              <h4
                :class="itemView==='grid-view'?'text-center':''"
                class="my-1"
              >
                <profile-name :user="user" />
              </h4>
              <profile-service-offers
                :user="user"
                :short="true"
              />
            </b-card-text>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center ">
            <div class="item-wrapper" />
            <!--            <b-button-->
            <!--              variant="light"-->
            <!--              tag="a"-->
            <!--              class="btn-wishlist"-->
            <!--              @click="toggleProductInWishlist(user)"-->
            <!--            >-->
            <!--              <feather-icon-->
            <!--                icon="HeartIcon"-->
            <!--                class="mr-50"-->
            <!--                :class="{'text-danger': user.isInWishlist}"-->
            <!--              />-->
            <!--              <span>Wishlist</span>-->
            <!--            </b-button>-->
            <b-button
              variant="primary"
              tag="a"
              class="btn-cart"
              @click="handleUserViewActionClick(user)"
            >
              <feather-icon
                icon="FileTextIcon"
                class="mr-50"
              />
              <span>{{ $t('Read more') }}</span>
            </b-button>
            <user-switcher
              :user="user"
              :short="false"
            />
            <assign-request-button
              :user="user"
              :short="false"
            />
          </div>
        </b-card>
      </section>
      <!-- Pagination -->
      <section v-if="itemView!=='map-view' && totalUsers">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalUsers"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
      <template #overlay>
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </template>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BCard,
  BCardText,
  BPagination,
  BOverlay,
  BButton,
  BCardBody,
  BSpinner,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText, scrollToTop } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { formatDateTime } from '@/filters/dateTime'
import { serviceOptions } from '@/mixins/options'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileName from '@/views/pages/profile/ProfileName.vue'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'
import ProfileServiceOffers from '@/views/pages/profile/ProfileServiceOffers.vue'
import { useUserLocation, useUserUi } from '@/views/apps/user/useUser'
import ProvidersListMapView from '@/views/apps/user/service-providers-list/ProvidersListMapView.vue'
import TopFilterSidebar from '@/views/apps/user/service-providers-list/ProvidersListTopFiltersSidebar.vue'
import UserSwitcher from '@/layouts/components/app-navbar/components/UserSwitcher.vue'
import store from '@/store'
import ServiceQueryShareButton from '@/views/apps/service/ServiceQueryShareButton.vue'
import { manageProposalActions } from '@/views/apps/service/proposal/useProposals'
import AssignRequestButton from '@/views/apps/user/AssignRequestButton.vue'
import { useFiltersSortingAndPagination, useUi, useRemoteData } from './useProvidersList'
import LeftFilterSidebar from './ProvidersListLeftFilterSidebar.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BCard,
    BCardText,
    BPagination,
    BOverlay,
    BButton,
    BCardBody,
    BSpinner,

    // SFC
    LeftFilterSidebar,
    TopFilterSidebar,

    ProfileName,
    ProfileAvatar,
    ProfileLanguages,
    ProfileServiceOffers,
    ProfileRating,
    ProvidersListMapView,
    ServiceQueryShareButton,
    UserSwitcher,
    AssignRequestButton,
  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],

  mounted() {
    if (this.$route.name === 'apps-service-providers-search') {
      this.setFilters()
    } else {
      this.updateUserCountryData()
    }
  },
  setup() {
    const {
      handleUserViewActionClick,
    } = useUserUi()

    const { updateUserCountryData } = useUserLocation()

    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useFiltersSortingAndPagination()

    const {
      handleCartActionClick,
      toggleProductInWishlist,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,
      getFirstGalleryImage,
      defaultImage,
      getOwnerProperty,
    } = usePointsUi()

    const {
      itemView, itemViewOptions, totalUsers,
    } = useUi()

    const ability = defineAbilityForCurrentUser()

    const {
      users, submittingFetch, fetchServiceProviders,
    } = useRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchServiceProviders` which can be triggered initially and upon changes of filters
    const fetchFilteredServiceProviders = () => {
      // if (filters.value.userId) {
      //   args.user_id = filters.value.userId
      // }
      //
      // if (sortBy.value.value) {
      //   args.sort_by = sortBy.value.value
      // }
      //
      // if (filters.value.postStatuses.length !== 0) {
      //   args.post_statuses = filters.value.postStatuses.join()
      // }
      // args.order = 'desc'

      const args = {
        q: filters.value.q,
        sort_by: 'id',
        order: 'desc',
        page: filters.value.page,
        per_page: filters.value.perPage,
      }

      if (filters.value.referral) {
        args.referral = filters.value.referral
      }

      if (filters.value.referral && filters.value.managed) {
        args.managed = filters.value.managed
      }

      if (filters.value.geolocationIsActive && filters.value.lat && filters.value.lng && filters.value.locationRadius) {
        args.lat = filters.value.lat
        args.lng = filters.value.lng
        args.location_radius = filters.value.locationRadius / 1000
      }

      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }
      if (filters.value.languages.length !== 0) {
        args.langs = filters.value.languages.join()
      }
      fetchServiceProviders(args)
        .then(response => {
          const { total, purposes } = response.data.meta
          users.value = response.data.data
          totalUsers.value = total
          filterOptions.pointPurposes.value = purposes
        })
    }

    watch([filters, sortBy], () => {
      mqShallShowLeftSidebar.value = false
      fetchFilteredServiceProviders()
    }, {
      deep: true,

    })

    watch([totalUsers], () => {
      if (totalUsers.value) {
        // itemView.value = 'grid-view'
        users.value.forEach(user => {
          if (user.managed) {
            user.referral_id = user.id
          }
        })
      }
    })

    const selectPurpose = (typeId, purposeIds) => {
      store.commit('point/setFilter', { point_type: typeId, point_purposes: purposeIds })
      scrollToTop()
    }
    const setFilters = () => {
      const point = store.getters['point/Point']
      if (!point.id) return
      updateUserCountryData(point.address.lat, point.address.lng)
      filters.value.referral = true
      filters.value.geolocationIsActive = true
      filters.value.lng = point.address.lng
      filters.value.lat = point.address.lat
      selectPurpose(point.point_type.id, point.point_purposes.map(el => el.id))
    }

    return {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      setFilters,

      // useShopUi
      itemView,
      itemViewOptions,
      totalUsers,

      // usePointsUi
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,

      getFirstGalleryImage,
      defaultImage,
      getPurposeTitle,
      getTypeTitle,

      handleUserViewActionClick,
      getOwnerProperty,

      // useServiceRequestsRemoteData
      submittingFetch,
      users,
      ability,
      fetchServiceProviders,
      updateUserCountryData,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.search-point.form-control{
  height: auto;
}
.actions{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height:0;
}
.ecommerce-application .grid-view .ecommerce-card .item-description {
  white-space: normal;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}@media all and (min-width:768px) and (max-width: 1440px) {
  .ecommerce-application .grid-view{
    grid-template-columns: 1fr 1fr;
  }
}
</style>
