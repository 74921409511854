export default {
  namespaced: true,
  state: {
    userRole: 'customer',
  },
  getters: {},
  mutations: {},
  actions: {
  },
}
