<template>

  <div
    v-if="!restricted"
    v-show="!loading"
    class="form-wizard"
  >
    <b-row>
      <b-col cols="12" lg="8" class="mb-1">
        <form-wizard
            ref="offerWizard"
            color="#FB9030"
            :title="null"
            :subtitle="null"
            class="offer"
            :class="layout==='vertical'?'vertical-steps ':''"
            :layout="layout"
            @on-change="()=>scrollToTop()"
        >

          <tab-content
              v-if="ability.can('manage', 'ServiceOffer')"
              :title="$t('point.owner')"
              :before-change="validationOfferOwner"
              icon="feather icon-user"
              :class="submitting?'locked':''"
          >

            <validation-observer
                ref="offerOwner"
            >
              <offer-owner
                  ref="offerOwner"
                  :data="pointData"
                  :title="false"
              />
            </validation-observer>

          </tab-content>
          <tab-content
              :title="$t('Overview')"
              :before-change="validationOverview"
              icon="feather icon-file-text"
              :class="submitting?'locked':''"
          >

            <validation-observer
                ref="offerOverview"
            >
              <offer-overview
                  ref="overview"
                  :data="pointData"
                  :user="user"
                  :title="false"
              />
            </validation-observer>

          </tab-content>
          <tab-content
              :title="$t('location.location')"
              :before-change="validationLocation"
              icon="feather icon-map-pin"
              :class="submitting?'locked':''"
          >
            <validation-observer
                ref="offerLocation"
            >
              <offer-open-map-modal
                  ref="map"
                  :data="pointData"
                  @update-location="val=>updateLocationEvent(val)"
                  :title="false"
              />
            </validation-observer>

          </tab-content>
          <tab-content
              :title="$t('Media')"
              :before-change="beforeChangeMediaTab"
              icon="feather icon-image"
              :class="submitting?'locked':''"
          >

            <validation-observer
                ref="requestMedia"
                tag="form"
            >
              <offer-media-dropzone
                  ref="media"
                  :point-data="pointDataSaved"
                  @media-files-uploaded="(val)=>mediaFilesUploadedEvent(val)"
                  :title="false"
              />
            </validation-observer>

          </tab-content>
          <tab-content
              v-if="!userId"
              :title="$t('Contact')"
              icon="feather icon-user"
              :before-change="validationUser"
              :class="submitting?'locked':''"
          >

            <validation-observer
                ref="requestUser"
                tag="form"
            >
              <b-container>
                <b-row class="justify-content-center">
                  <b-col
                      cols="12"
                      lg="6"
                  >
                    <register-form
                        :redirect-user="false"
                        :register-button-hidden="true"
                        :attempt-to-authorize="attemptToAuthorize"
                        @registration-error="submitting=false"
                        @user-logged-in="(val)=>userLoggedInEvent(val)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </validation-observer>

          </tab-content>
          <b-button
              slot="prev"
              class="wizard-btn"
              type="button"
              variant="primary"
              :disabled="submitting"
          >
            {{ $t('Back') }}
          </b-button>
          <b-button
              slot="next"
              class="wizard-btn"
              type="button"
              variant="primary"
          >
            {{ $t('Continue') }}
          </b-button>
          <b-button
              slot="finish"
              class="wizard-btn"
              type="button"
              variant="primary"
              :disabled="submitting"
          >
        <span v-if="submitting">
          <b-spinner
              type="grow"
              small />
          {{ $t('Loading...') }}
        </span>
            <span v-if="!submitting">
          {{ finishButtonText() }}
        </span>

          </b-button>
        </form-wizard>
      </b-col>
      <b-col cols="12" lg="4" >
        <b-card no-body>
          <b-card-body>
            <offer-add-timeline :user-id="userId"></offer-add-timeline>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BSpinner,
  BContainer,
    BCard,
    BCardBody,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

import store from '@/store'
import router from '@/router'
import { showToast } from '@/mixins/notification/toasts'
import { FormWizard, TabContent } from 'vue-form-wizard'
import RegisterForm from '@/views/pages/authentication/components/RegisterForm.vue'
import { getUserData } from '@/auth/utils'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import OfferOpenMapModal from './OfferOpenMapModal.vue'
import OfferOverview from './OfferOverview.vue'
import OfferMediaDropzone from './OfferMediaDropzone.vue'
// import RequestMedia from './RequestMedia.vue'
import pointStoreModule from '../pointStoreModule'
import OfferOwner from './OfferOwner.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import i18n from '@/libs/i18n'
import OfferAddTimeline from '@/views/apps/service/offer/OfferAddTimeline.vue'
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BContainer,
    BCard,
    BCardBody,
    OfferOverview,
    ValidationObserver,
    FormWizard,
    TabContent,
    OfferOpenMapModal,
    RegisterForm,
    OfferOwner,
    // RequestMedia,
    OfferMediaDropzone,
    OfferAddTimeline,
  },
  mixins: [showToast],

  props: {
    layout: {
      type: String,
      default: () => 'horizontal',
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },

  },
  data() {
    return {
      pointData: {},
      pointDataSavedState: null,
      pointDataSaved: {},
      location: {},
      error404: false,
      restricted: false,
      loading: false,
      userDada: {},
      attemptToAuthorize: 0,
      submitting: false,

    }
  },
  computed: {
    offerViewport() {
      if (this.pointData.point_type_id && this.user) {
        const offer = this.user.service_offers.find(offer => offer.point_type.id === this.pointData.point_type_id)
        if (offer && offer.id) {
          return [
            { lat: offer.viewport_north_lat, lng: offer.viewport_east_lng },
            { lat: offer.viewport_south_lat, lng: offer.viewport_west_lng },
          ]
        }
      }
      return []
    },

  },

  created() {
    this.pointData = JSON.parse(this.initPointData)
    store.commit('point/deletePoint')
  },
  methods: {
    scrollToTop() {
      const rootEle = this.$refs.offerWizard.$el
      rootEle.scrollIntoView()
    },
    userLoggedInEvent(userData) {
      this.userDada = userData
      this.addOffer()
    },
    redirectToOfferDetails() {
      if (this.pointDataSaved.id) {
        // router.push({ path: `/service/offer/${this.pointDataSaved.id}` })
        router.push({ name: 'pages-account-setting', query: { tab: 'services' } })
      }
    },
    mediaFilesUploadedEvent() {
      this.redirectToOfferDetails()
    },
    finishButtonText() {
      return !this.userId ? this.$t('Add Service Offer') : this.$t('Add Service Offer')
    },
    beforeChangeMediaTab() {
      if (this.userId) {
        // this.checkIfOfferAdded()
        this.submit()
      }
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },
    isDataChanged() {
      if (!this.pointDataSaved.id) return false
      // this.pointData.point_type_id = this.$refs.overview.pointType
      // this.pointData.point_purpose_ids = this.$refs.overview.pointPurposes
      return (this.pointDataSavedState !== JSON.stringify(this.pointData))
    },
    checkIfOfferAdded() {
      const offer = store.getters['point/Point']
      return new Promise((resolve, reject) => {
        if (offer.id) {
          resolve(true)
          router.push({ path: `/service/offer/${offer.id}` })
        } else {
          reject()
          this.showToast({ status: 'error', statusText: 'Something went wrong' }, 'Offer')
        }
      })
    },
    rerenderMap() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    validationOverview() {
      return new Promise((resolve, reject) => {
        this.$refs.offerOverview.validate().then(success => {
          if (success) {
            resolve(true)
            // TODO find more efficient way to fix issue related to rendering only part of map
            this.rerenderMap()
          } else {
            reject()
          }
        })
      })
    },
    validationLocation() {
      return new Promise((resolve, reject) => {
        this.$refs.offerLocation.validate().then(success => {
          if (success) {
            resolve(true)
            // this.submit()
          } else {
            reject()
          }
        })
      })
    },
    validationUser() {
      return new Promise((resolve, reject) => {
        this.$refs.requestUser.validate().then(success => {
          if (success) {
            resolve(true)
            this.submit()
          } else {
            reject()
          }
        })
      })
    },
    validationOfferOwner() {
      return new Promise((resolve, reject) => {
        this.$refs.offerOwner.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    updateLocationEvent(location) {
      this.location = location
      this.pointData.lat = this.$refs.map.location.position.lat
      this.pointData.lng = this.$refs.map.location.position.lng
    },

    showAlert404() {
      this.$swal({
        title: this.$t('Offer Not Found'),
        text: this.$t('Please choose next action:'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Back to requests list'),
        cancelButtonText: this.$t('Add Offer'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          router.push({ name: 'apps-service-my-requests' })
        } else {
          router.push({ name: 'apps-service-offer-add' })
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You didn't save the offer"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.submitting = true
      if (this.pointDataSaved.id) {
        if (this.isDataChanged()) {
          this.updateOffer(this.pointDataSaved.id)
        }
      } else if (!this.userId) {
        this.attemptToAuthorize += 1
      } else {
        this.addOffer()
      }
    },

    offerData() {
      const { pointData } = this
      // pointData.lat = this.$refs.map.location.position.lat
      // pointData.lng = this.$refs.map.location.position.lng
      pointData.location_radius = this.$refs.map.location.radius / 1000
      // pointData.point_type_id = this.$refs.overview.pointType
      // pointData.point_purpose_ids = this.$refs.overview.pointPurposes

      if (this.user) {
        pointData.receiver_id = this.user.id
      }

      delete pointData.id
      delete pointData.address
      delete pointData.user
      delete pointData.user_id
      delete pointData.point_type
      delete pointData.point_purpose
      return pointData
    },
    requestAddress() {
      return {
        country: this.location.address.country,
        country_code: this.location.address.country_code,
        postcode: this.location.address.postcode,
        state: this.location.address.state,
        locality: this.location.address.locality,
        road: this.location.address.road,
        house_number: this.location.address.house_number,
        apartment_number: this.location.address.apartment_number,
        address_confirmed: this.location.address_confirmed,
        viewport: this.location.viewport,
      }
    },
    addOffer() {
      const payload = { data: this.offerData(), address: this.requestAddress() }
      let url = 'point/addServiceOffer'
      if (payload.data.user_phone) {
        url = 'point/addServiceOfferManaged'
      }
      store.dispatch(url, payload)
        .then(response => {
          if ([200, 201, 'success'].includes(response.status)) {
            this.pointDataSavedState = JSON.stringify(this.pointData)
            this.pointDataSaved = response.data.data
            // router.push({ path: `/service/offer/${this.pointDataSaved.id}` })
            store.dispatch('account/fetchUserCurrent')
          }
          this.showToast(response, 'Offer')
        })
        .finally(() => {
          this.submitting = false
        })
    },
    updateOffer(id) {
      const payload = { data: this.offerData(), address: this.requestAddress(), id }
      store.dispatch('point/updateServiceOffer', payload).then(response => {
        this.pointDataSavedState = JSON.stringify(this.pointData)
        this.showToast(response, 'Offer')
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDataChanged() && !this.error404 && !this.restricted) {
      this.showAlert(next)
    } else {
      next()
    }
  },

  setup() {
    // const POINT_APP_STORE_MODULE_NAME = 'point'
    //
    // // Register module
    // if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
    // })
    const pointData = {
      point_type_id: null,
      point_purpose_ids: [],
      name: '',
      description: '',
      lng: 0,
      lat: 0,
      post_status: 'published',
      lang: i18n.locale,
      tags:[],
    }
    const initPointData = JSON.stringify(pointData)
    const userId = getUserData().id

    const ability = defineAbilityForCurrentUser()

    return {
      pointData,
      ability,
      initPointData,
      userId,

    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
