<template>

  <b-button
    v-if="isManagedUser(user) && request.id"
    :id="user?`manage${user.referral_id}`:''"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="warning"
    :class="short?'btn-icon':'btn-cart'"
    @click="handleAddProposalActionClick(request, user)"
  >

    <span
      v-if="!short"
      class="align-middle"
    >
      <feather-icon
        icon="CalendarIcon"
        :class="short?'':'mr-50'"
        :size="short?'22':'16'"
      />{{ $tc('Apply Now',request.id) }}</span>

  </b-button>

</template>

<script>
import {
  VBTooltip, BButton,
} from 'bootstrap-vue'
import { useUserUi } from '@/views/apps/user/useUser'
import Ripple from 'vue-ripple-directive'
import { manageProposalActions } from '@/views/apps/service/proposal/useProposals'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    short: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup() {
    const { isManagedUser } = useUserUi()
    const { handleAddProposalActionClick } = manageProposalActions()
    const request = computed(() => store.getters['point/Point'])
    return {
      isManagedUser, handleAddProposalActionClick, request,
    }
  },
}
</script>
