<template>
  <div>
    <b-row class="text-center mt-1">
      <b-col>
        <span><b>{{ appName }}</b></span> - {{ $t('about_the_project') }}
      </b-col>
      <b-col
        v-if="userAuthorized"
        cols="12"
        md="4"
        class="mt-1 mt-sm-0"
      >
        <b-link :to="{path:`/faq` }">
          {{ $t('Have a question?') }}
        </b-link><br>
        <b-link :to="{path:`/${$i18n.locale}/privacy-policy` } ">
          {{ $t('Privacy policy') }}
        </b-link><br>
        <b-link :to="{path:`/${$i18n.locale}/public-offer` }">
          {{ $t('Public Offer') }}
        </b-link><br>
        <b-link :to="{path:`/pricing` }">
          {{ $t('Pricing') }}
        </b-link><br>
      </b-col>
      <b-col
        v-if="userAuthorized"
        cols="12"
        md="4"
        class="mt-1 mt-sm-0"
      >
        <donates />
        <b-img
          :src="require('@/assets/images/icons/mastercard_icon.svg')"
          height="32px"
        />
        <b-img
          :src="visaImgUrl"
          height="32px"
        />

      </b-col>
    </b-row>
    <b-row class="text-center text-muted pt-1">
      <b-col>
        <p class="small">        ©
          2022 - {{ new Date().getFullYear() }}
          {{ appName }}
          {{ appVersion }}</p>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BLink, BImg, BRow, BCol,
} from 'bootstrap-vue'
import Donates from '@/layouts/components/Donates.vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    Donates,
  },
  data() {
    return {
      appVersion: `v${process.env.VUE_APP_VERSION}`,
    }
  },
  computed: {
    visaImgUrl() {
      // eslint-disable-next-line global-require
      let img = require('@/assets/images/icons/Visa_Brandmark_Blue_RGB_2021.svg')
      if (this.skin === 'dark') {
        // eslint-disable-next-line global-require
        img = require('@/assets/images/icons/Visa_Brandmark_White_RGB_2021.svg')
        return img
      }
      return img
    },
  },
  setup() {
    const { appName } = $themeConfig.app
    const userAuthorized = computed(() => store.state.account.user.id)
    const {
      skin,
    } = useAppConfig()
    return {
      appName,
      skin,
      userAuthorized,
    }
  },
}
</script>
