<template>
  <div>

    <b-button
      v-if="!phones.length"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      block
      class="my-2"
      @click="fetchPhones()"
    >
      <span
        v-if="submittingFetchPhones"
        class="align-middle"
      >
        <b-spinner
          type="grow"
          small
        />
        {{ $t('Loading...') }}
      </span>
      <span
        v-else
        class="align-middle"
      >      <feather-icon
        icon="EyeIcon"
        class="mr-50"
      />{{ $t('Contact Phones') }}</span>
    </b-button>

    <!--    <b-alert-->
    <!--      v-if="phonesRequested && !phones.length"-->
    <!--      show-->
    <!--      variant="dark"-->
    <!--      class="mt-1"-->
    <!--    >-->
    <!--      <div class="alert-body">-->
    <!--        <feather-icon-->
    <!--          class="mr-25"-->
    <!--          icon="InfoIcon"-->
    <!--          size="16"-->
    <!--        />-->
    <!--        <span class="ml-25">{{ emptyPhonesAlert }}</span>-->
    <!--      </div>-->
    <!--    </b-alert>-->
    <!-- Modal Form -->
    <b-modal
      id="modal-form"
      ref="modal-form"
      v-model="displayModal"
      centered
      hide-footer
      :static="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>
        <b-container fluid>
          <b-button
            class="close btn-icon"
            variant="outline-secondary"
            @click="displayModal=false"
          >
            <feather-icon
              icon="XIcon"
              size="26"
            />
          </b-button>
          <!--          <b-media-->
          <!--            no-body-->
          <!--          >-->
          <!--            <b-media-aside>-->
          <!--              <b-avatar-->
          <!--                rounded-->
          <!--                variant="primary"-->
          <!--                size="24"-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="AlertCircleIcon"-->
          <!--                  size="18"-->
          <!--                />-->
          <!--              </b-avatar>-->
          <!--            </b-media-aside>-->
          <!--            <b-media-body class="align-self-center">-->
          <!--              <span>{{ $t('user.phones_are_hidden') }}</span>-->
          <!--            </b-media-body>-->
          <!--          </b-media>-->
        </b-container>
      </template>
      <!-- Modal Form Body -->
      <!--Form-->
      <b-container
        fluid
      >
        <b-alert
          variant="primary"
          class="mb-2"
          show
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            {{ emptyPhonesAlert }}
          </div>
        </b-alert>
        <b-button
          v-if="!userId"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          @click="$router.push({name:'auth-register'})"
        >
          <span class="align-middle">{{ $t('auth.Sign up') }}</span>
        </b-button>
        <b-button
          v-if="!userId"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          @click="$router.push({name:'auth-login'})"
        >
          <span class="align-middle">{{ $t('auth.Sign in') }}</span>
        </b-button>
      </b-container>
    </b-modal>
    <div
      v-if="phones.length"
      class="py-2"
    >
      <div
        v-for="(phone, index) in phones"
        :key="index"
      >
        <div class="mb-1 justify-content-center  d-flex flex-column  align-items-center">
          <div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              :href="getPhoneLink(phone)"
              class="btn-icon"
            >
              <span
                class="h5 text-primary"
              > +{{ phone.country_code }} {{ phone.value | VMask(getPhoneMask(phone.value)) }}</span>

            </b-button>
          </div>
          <div
            v-if="phone.messengers.length"
            class="messengers"
          >
            <b-button
              v-for="(messenger,index) in phone.messengers"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              :href="getMessengerLink(messenger, phone)"
            >
              <b-avatar
                :src="messenger.icon"
                alt="messenger"
                size="m"
                variant="light-primary"
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BAlert,
  BButton,
  BModal,
  BContainer,
  BSpinner,
  VBTooltip,

} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { getUserAvatarText, findPath } from '@/mixins/functions'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { messengers } from '@/mixins/options'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import { getPhoneMaskType } from '@/utils/location/phoneCodeCountries'

export default {
  components: {
    BAvatar,
    BAlert,
    BButton,
    BModal,
    BContainer,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [getUserAvatarText],
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    emptyPhonesMessage: {
      type: String,
      default: () => {},
      required: false,
    },
  },

  methods: {
    getMessengerLink(messenger, phone) {
      if (typeof messenger !== 'object' || !('link' in messenger)) return ''
      let { link } = messenger

      link = link.concat(this.getPhoneFullNumber(phone))
      const url = new URL(link)
      const text = i18n.t('I\'m interested in your services')
      url.searchParams.append('text', text)
      link = url.href

      return link
    },

  },
  setup(props) {
    const phones = ref([])
    const phonesRequested = ref(false)
    const { messengersList } = messengers()
    const submittingFetchPhones = ref(false)
    const displayModal = ref(false)
    const emptyPhonesAlert = computed(() => (props.emptyPhonesMessage ? props.emptyPhonesMessage : i18n.t('user.phones_available_to_authorised_users_only')))

    const getPhoneFullNumber = phone => encodeURIComponent('+').concat(phone.country_code).concat(phone.value)

    const getPhoneLink = phone => 'tel:'.concat(getPhoneFullNumber(phone))

    const getPhoneMask = phone => getPhoneMaskType(phone)

    const userId = getUserData().id

    const fetchPhones = async () => {
      if (!userId) {
        displayModal.value = true
        return false
      }

      submittingFetchPhones.value = true

      if ('phones' in props.user && props.user.phones.length) {
        phones.value = props.user.phones
        if (phones.value.length) {
          phones.value.forEach(el => {
            if (el.messengers.length) {
              el.messengers = messengersList.filter(obj => el.messengers.includes(obj.slug))
            }
          })
        } else {
          displayModal.value = true
        }
      } else {
        await store.dispatch('user/fetchServiceProviderPhones', { id: props.user.id })
          .then(response => {
            const { data } = response.data
            phones.value = data
            if (phones.value.length) {
              phones.value.forEach(el => {
                if (el.messengers.length) {
                  el.messengers = messengersList.filter(obj => el.messengers.includes(obj.slug))
                }
              })
            } else {
              displayModal.value = true
            }
          })
          .catch(error => {
            if (findPath(error, 'review_phones_limit') === 'response.data.errors') {
              displayModal.value = true
              emptyPhonesAlert.value = i18n.t('message.review_phones_limit')
            }
          })
          .finally(() => {

          })
      }

      phonesRequested.value = true
      submittingFetchPhones.value = false
      return true
    }

    return {
      emptyPhonesAlert,
      phones,
      phonesRequested,
      userId,
      getPhoneLink,
      getPhoneFullNumber,
      fetchPhones,
      submittingFetchPhones,
      displayModal,
      getPhoneMask,
    }
  },
}

</script>
<style scoped>
.messengers .btn.btn-icon{
padding: 0.2rem;
}
</style>
