<template>
  <div>
    <l-map
      ref="map"
      :center="[
        position.lat,
        position.lng
      ]"
      :options="map.options"
      :style="`height:${mapHigh}px; width: 100%`"
      @dblclick="onMapClick"
    >
      <l-control-scale
        position="topright"
        :imperial="true"
        :metric="false"
      />
      <l-marker
        visible
        :draggable="true"
        :lat-lng.sync="location.position"
        @dragstart="dragging = true"
        @dragend="coordinatesAreChanged()"
      />
      <l-tile-layer :url="tileProvider.url" />
      <!--              <l-geosearch :options="geoSearchOptions" />-->
      <div
        v-for="provider in data"
        :key="provider.id"
      >
        <l-marker
          v-for="offer in provider.service_offers"
          :key="offer.id"
          visible
          :draggable="false"
          :lat-lng="markerPosition(offer)"
        >
          <l-icon>
            <b-img
              :src="getAvatar(provider)"
              height="36"
              rounded="circle"
              :alt="provider.name"
            />
          </l-icon>
          <l-popup>
            <b-link @click="handleUserViewActionClick(provider)">
              <div class="d-flex justify-content-start  align-items-center">
                <profile-avatar
                  :user="provider"
                  avatar-size="48"
                />
                <div>
                  <h5>{{ provider.name }}</h5>
                  <small class="text-muted">
                    <b-badge
                      pill
                      variant="light-primary"
                    >
                      {{ getTypeTitle(offer.point_type.slug) }}
                    </b-badge>
                  </small>
                </div>
              </div>
              <div class="d-flex justify-content-between  align-items-center">
                <profile-languages :user="provider" />
              </div>
            </b-link>
          </l-popup>
        </l-marker>
      </div>
      <l-circle
        v-if="location.radius && filters.geolocationIsActive"
        ref="circle"
        :lat-lng.sync="location.position"
        :radius="location.radius"
        color="#2980B9"
      />
    </l-map>
  </div>
</template>

<script>
import {
  BImg,
  BBadge,
  BLink,
} from 'bootstrap-vue'

import {
  LMap, LTileLayer, LMarker, LCircle, LPopup, LIcon, LControlScale,
} from 'vue2-leaflet'
import { heightFade } from '@core/directives/animations'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import * as locationService from '@/utils/location/location'
/* eslint-disable global-require */

import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileName from '@/views/pages/profile/ProfileName.vue'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'

import { useUserLocation, useUserUi } from '@/views/apps/user/useUser'
import { serviceOptions } from '@/mixins/options'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BImg,
    BBadge,
    BLink,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPopup,
    LIcon,
    LControlScale,
    ProfileLanguages,
    ProfileAvatar,
    ProfileName,
    ProfileRating,

  },
  directives: {
    'height-fade': heightFade,
  },

  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    filters: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      point: this.data,
      dragging: false,
      loading: false,
      error: null,
      location: {
        address: {
          country: '',
          country_code: '',
          postcode: '',
          locality: '',
          road: '',
          state: '',
          house_number: '',
          apartment_number: '',
        },
        position: {
          lat: 0,
          lng: 0,
        },
        radius: this.filters.locationRadius,
        viewport: {
          _southWest: {
            lat: 0,
            lng: 0,
          },
          _northEast: {
            lat: 0,
            lng: 0,
          },
        },

      },
      map: {
        options: {
          zoomControl: true,
          attributionControl: false,
          zoomSnap: false,
          zoom: 10,
          minZoom: 6,
          maxZoom: 18,
          tap: true,
          dragging: true,
          scrollWheelZoom: false,
          doubleClickZoom: false,
        },
      },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      geoSearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            'accept-language': this.$i18n.locale, // render results in language
            // countrycodes: [this.$i18n.locale], // limit search results for country
          },
        }),
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
      },
      tooltip: {
        options: {
          permanent: true,
          direction: 'top',
          interactive: true,
          noWrap: true,
          opacity: 0.9,
        },
      },
    }
  },
  computed: {
    mapHigh() {
      return document.body.offsetHeight
    },
  },
  watch: {
    '$store.state.user.geoData': {
      deep: true,
      immediate: true,
      async handler() {
        await this.$nextTick(() => {
          this.setLocation({
            lat: this.position.lat,
            lon: this.position.lng,
          })
          this.setLocationViewport()
        })
      },
    },
    'location.radius': {
      deep: true,
      async handler() {
        await this.$nextTick(() => {
          this.setLocationViewport()
        })
      },
    },
    'filters.locationRadius': {
      async handler(val) {
        await this.$nextTick(() => {
          this.location.radius = val
        })
      },
    },
  },
  mounted() {
    this.setLocation({ lat: this.position.lat, lon: this.position.lng })
  },
  methods: {

    markerPosition(offer) {
      return { lat: offer.lat, lng: offer.lng }
    },
    async coordinatesAreChanged() {
      await this.fetchLocationByCoordinates(this.location.position.lat, this.location.position.lng)
    },
    setLocation(location) {
      this.filters.lat = location.lat
      this.filters.lng = location.lon
      this.setPosition(location.lat, location.lon)
    },
    setPosition(lat, lng) {
      this.location.position = { lat, lng }
    },
    setLocationViewport() {
      setTimeout(() => {
        const circle = this.$refs.circle.mapObject
        this.$refs.map.mapObject.fitBounds(circle.getBounds())
        this.location.viewport = circle.getBounds()
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },

    async fetchCurrentLocation() {
      try {
        this.setLoadingState()
        const location = await locationService.currentLocation(this.$i18n.locale)
        if (location) {
          this.updateUserGeoData(location)
        }
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchLocationBySearchQuery(query, structured = false) {
      try {
        this.setLoadingState()
        const location = await locationService.locationBySearchQuery(query, this.$i18n.locale, structured)
        if (location && this.locationIsValid(location[0])) {
          this.updateUserGeoData(location)
        }
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchLocationByCoordinates(lat, lng) {
      try {
        this.setLoadingState()
        const location = await locationService.locationByCoordinates({ latitude: lat, longitude: lng }, this.$i18n.locale)
        if (location) {
          this.updateUserGeoData(location)
        }
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    setError(error) {
      this.error = error
      this.loading = false
    },
    setLoadingState() {
      this.error = null
      this.loading = true
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      await this.fetchLocationByCoordinates(value.latlng.lat, value.latlng.lng)
    },
    onSearch(value) {
      const { location } = value
      this.setPosition(location.y, location.x)
      this.fetchLocationByCoordinates(location.y, location.x)
    },
  },
  setup() {
    const { getTypeTitle } = serviceOptions
    const {
      getAvatar, handleUserViewActionClick,
    } = useUserUi()

    const { getUserLat, getUserLng, updateUserGeoData } = useUserLocation()
    const position = computed(() => ({ lat: getUserLat.value, lng: getUserLng.value }))
    return {
      getAvatar,
      getTypeTitle,
      handleUserViewActionClick,
      updateUserGeoData,
      position,
    }
  },

}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style scoped lang="scss">
.leaflet-marker-icon>img{
  background-color: #fff;
}
</style>
