<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        {{ $t('subscription.pricing_plans') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('subscription.all_basic_functionality_is_available_FREE_of_charge_for_customers') }}
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50 text-center text-uppercase">
        <!--        <h6 class="m-1">-->
        <!--          Monthly-->
        <!--        </h6>-->
        <!--        <b-form-checkbox-->
        <!--          id="priceSwitch"-->
        <!--          v-model="status"-->
        <!--          name="price-switch"-->
        <!--          value="annually"-->
        <!--          unchecked-value="monthly"-->
        <!--          switch-->
        <!--          @input="toggleMonthlyPlan"-->
        <!--        />-->
        <!--        <h6 class="m-1">-->
        <!--          Annually-->
        <!--        </h6>-->
        <h6 class="m-1 ">
          <a @click="toggleUserPlan('customer')">
            {{ $t('Customer') }}
          </a>
        </h6>
        <b-form-checkbox
          id="userPlanSwitch"
          v-model="$store.state.appLanding.userRole"
          name="user-plan-switch"
          value="contractor"
          unchecked-value="customer"
          switch
          disabled
          class="custom-control-warning"
        />
        <h6 class="m-1">
          <a @click="toggleUserPlan('contractor')">
            {{ $t('Contractor') }}
          </a>
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        md="12"
        class="mx-auto"
      >
        <b-row class="match-height">
          <b-col
            v-for="(plan, index) in pricing"
            :key="index"
            md="4"

          >
            <b-card
              :border-variant="`${(plan.popular)?mainColor:''}`"
              no-body
            >
              <div
                class="rounded-top text-center"
                :class="`bg-light-${mainColor}`"
              >
                <div
                  class="pricing-badge text-right p-1"
                >
                  <b-badge
                    :variant="`light-${mainColor}`"
                    pill
                    v-if="plan.badge"
                  >
                    {{ $t(plan.badge) }}
                  </b-badge>
                </div>
                <!-- img -->
                <div class="height-140 d-flex justify-content-center align-content-end pb-2">
                  <b-img
                      v-if="plan.img"
                      :src="plan.img"
                      :height="132"
                      :alt="plan.title"
                  />
                </div>

                <!--/ img -->
                <h3 class="text-uppercase">{{ $t(plan.title) }}</h3>
                <b-card-text class="font-weight-bolder">
                  {{ $t(plan.subtitle) }}
                </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup
                      :class="`text-${mainColor}`"
                      class="font-medium-1 font-weight-bold"
                    >{{ getCurrencySymbol() }}</sup>
                    <span
                      :class="`text-${mainColor}`"
                      class="pricing-basic-value font-weight-bolder"
                    >{{ monthlyPlanShow ? plan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('month') }}</sub>
                  </div>
                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ plan.yearlyPlan.totalAnnual }} / {{ $t('year') }}</small>
                </div>
                <!--/ annual plan -->
              </div>
              <b-card-body>
                <div class="align-items-center">
                  <!-- plan feature -->

                    <div
                      v-for="(feature,index) in plan.planFeatures"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ $t(feature.name) }}</span>

                        <span>
                          <span v-if="feature.qtyMin" ><b>{{feature.qtyMin}}</b><small v-if="feature.per" class="text-lowercase"> / {{$t(feature.per)}}</small></span>
                          <span v-if="feature.qtyMax"><b>{{feature.qtyMax}}</b><small v-if="feature.per" class="text-lowercase"> / {{$t(feature.per)}} </small></span>
                          <b-avatar variant="success" v-if="feature.value && feature.value==='yes'" size="sm">
                            <feather-icon icon="CheckIcon" size="14" />
                          </b-avatar>
                          <b-avatar variant="danger" v-if="feature.value && feature.value==='no'" size="sm">
                            <feather-icon icon="XIcon" size="14" />
                          </b-avatar>
                          <span v-if="feature.value && !['yes','no'].includes(feature.value)"><b>{{$t(feature.value)}}</b></span>
                        </span>

                      </div>
                      <hr>
                    </div>

                  <!--/ plan feature -->

                </div>
              </b-card-body>
              <b-card-footer>
                <!-- buttons -->

                <div v-if="index==='basicPlan'">
                  <b-button
                      v-if="$store.state.appLanding.userRole==='customer'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      :variant="mainColor"
                      @click="$router.push({ name: 'apps-service-request-add-wizard'})"
                  >
                    <feather-icon
                        icon="PhoneCallIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">
                      {{ $t('Request services') }}
                    </span>
                  </b-button>
                  <b-button
                      v-else
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      :variant="mainColor"
                      @click="$router.push({ name: 'apps-service-offer-add-wizard'})"
                  >
                    <feather-icon
                        icon="BriefcaseIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">
                      {{ $t('Offer services') }}
                    </span>
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                      v-if="index!=='basicPlan'"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      :variant="`${resolveButtonVariant(plan)}`"
                      :href="getLink(plan.title)"
                  >
                    <feather-icon
                        icon="TrendingUpIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">
                      {{ $t('subscription.upgrade_plan') }}
                    </span>

                  </b-button>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t('Still undecided?') }} {{ $t('Just try it!') }}
              </h3>
              <h5>{{ $t('Go to the list of contractors available in your area and select a contractor yourself') }}</h5>
<!--              <a-->
<!--                v-scroll-to="{-->
<!--                  el: '#quote',-->
<!--                  offset: -100,-->
<!--                }"-->
<!--                href="#"-->
<!--              >-->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mt-lg-3"
                  @click="$router.push({name:'apps-service-offer-list'})"
                >
                  {{ $t('View list of service providers') }}
                </b-button>
<!--              </a>-->

            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BCardBody, BAvatar, BCardFooter
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  currency,
} from '@/mixins/options'
import ScrollTo from 'vue-scrollto'
import store from '@/store'
import landingStoreModule from '@/views/pages/landing/landingStoreModule'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BCardBody,
    BAvatar,
    BCardFooter
  },
  directives: {
    Ripple,
    ScrollTo,
  },
  data() {
    return {
      mainColor: 'primary',
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      plansQuantity: null,
    }
  },

  watch: {
    '$store.state.appLanding.userRole': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.toggleUserPlan(newVal)
        }
      },
    },
  },
  created() {
    this.toggleUserPlan()
  },
  methods: {

    resolveButtonVariant(plan) {
      return plan.popular ? this.mainColor : `outline-${this.mainColor}`
    },
    // toggleMonthlyPlan() {
    //   this.monthlyPlanShow = this.status === 'monthly'
    // },
    toggleUserPlan(user) {
      if (user) {
        store.state.appLanding.userRole = user
      }
      const plans = this.pricingPlans.find(value => value[store.state.appLanding.userRole])
      this.pricing = plans[store.state.appLanding.userRole]
      this.plansQuantity = Object.keys(this.pricing).length
      this.mainColor = store.state.appLanding.userRole === 'customer' ? 'primary' : 'warning'
    },
  },
  setup() {
    const LANDING_APP_STORE_MODULE_NAME = 'appLanding'

    // Register module
    if (!store.hasModule(LANDING_APP_STORE_MODULE_NAME)) store.registerModule(LANDING_APP_STORE_MODULE_NAME, landingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LANDING_APP_STORE_MODULE_NAME)) store.unregisterModule(LANDING_APP_STORE_MODULE_NAME)
    })
    const pricingPlans = ref([])
    pricingPlans.value = [
      {
        customer: {
          basicPlan: {
            title: 'subscription.user',
            popular:false,
            img: require('@/assets/images/svg/user.svg'),
            subtitle: 'subscription.a_simple_start_for_everyone',
            monthlyPrice: 0,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:2},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'no'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: '',
          },
          standardPlan: {
            title: 'subscription.landlord',
            popular:true,
            img: require('@/assets/images/svg/landlord.svg'),
            subtitle: 'subscription.for_property_owners',
            monthlyPrice: 29,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.popular',
          },
          enterprisePlan: {
            title: 'subscription.agency',
            popular:false,
            img: require('@/assets/images/svg/agency.svg'),
            subtitle: 'subscription.for_small_and_medium_sized_businesses',
            monthlyPrice: 99,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:30},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
            // 'Search for contractors and provide services at the same time',
            // 'Unlimited amount of proposals on services requests',
            // 'Unlimited number of requests for services',
            // 'Unlimited number of service offers',
            // 'Calendar of events',
            ]
          },
        },
      },
      {
        contractor: {
          basicPlan: {
            title: 'subscription.freelancer',
            popular:false,
            img: require('@/assets/images/svg/freelancer.svg'),
            subtitle: 'subscription.a_simple_start_for_everyone',
            monthlyPrice: 10,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:2},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'no'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.first_month_free_of_charge',
          },
          standardPlan: {
            title: 'subscription.professional',
            popular:true,
            img: require('@/assets/images/svg/professional.svg'),
            subtitle: 'subscription.for_self_employed_contractors',
            monthlyPrice: 49,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.popular',
          },
          enterprisePlan: {
            title: 'subscription.enterprise',
            popular:false,
            img: require('@/assets/images/svg/enterprise.svg'),
            subtitle: 'subscription.for_small_and_medium_sized_businesses',
            monthlyPrice: 299,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'yes'},
              {name:'Dashboards', value:'yes'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: '',
          },
        },
      },
    ]
    const { getCurrencySymbol } = currency()

    const getLink = (plan)=>{
      const tel = encodeURIComponent('+').concat('34603334187')
      const link = 'https://wa.me/'.concat(tel)
      const url = new URL(link)
      url.searchParams.append('text', plan)
      return url.href
    }
    return {
      pricingPlans,
      getCurrencySymbol,
      getLink,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-pricing.scss';
.pricing-card .card-body>img{
  height: 96px!important;
}
.card .card-footer{
  border-top: none!important;
}
.dark-layout .pricing-free-trial {
  background-color: #283046;
}
.pricing-badge{
  min-height: 4rem;
}
</style>
