import { render, staticRenderFns } from "./ServiceQueryShareButton.vue?vue&type=template&id=2c7ab112&scoped=true&"
import script from "./ServiceQueryShareButton.vue?vue&type=script&lang=js&"
export * from "./ServiceQueryShareButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7ab112",
  null
  
)

export default component.exports