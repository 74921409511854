<template>
  <div>
    <b-tooltip
      target="shareQueryButton"
      placement="top"
      triggers="click"
    >
      {{ $t('common.link_copied') }}
    </b-tooltip>
    <b-button
      id="shareQueryButton"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      @click="copyServiceQueryLink()"
    >
      <feather-icon
        icon="Share2Icon"
        size="22"
      />

    </b-button>
  </div>

</template>

<script>
import {
  BTooltip,
  BButton,

  VBTooltip,

} from 'bootstrap-vue'

import { useServicesQueryHandler } from '@/views/apps/service/usePoints'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BTooltip,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  methods: {
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    copyServiceQueryLink() {
      navigator.clipboard.writeText(this.getServiceQueryLink())
        .then(
          () => {
            this.showTooltip('shareQueryButton')
            setTimeout(code => {
              this.hideTooltip(code)
            }, 1000)
          },
          () => {
            /* clipboard write failed */
          },
        )
    },
  },
  setup() {
    const { getServiceQueryLink } = useServicesQueryHandler()

    return {
      getServiceQueryLink,
    }
  },
}

</script>
<style scoped>

</style>
