<template>
  <referrals />
</template>

<script>

import Referrals from '@/views/apps/referral/referrals/Referrals.vue'

export default {
  components: {
    Referrals,
  },

  setup() {
    return {
    }
  },
}
</script>
<style scoped>

</style>
