<template>
  <section id="promo">

    <referral />

  </section>
</template>

<script>

import Referral from '@/views/apps/referral/Referral.vue'

export default {
  components: {
    Referral,
  },

}
</script>
