<template>
  <div
    v-if="serviceProviderProfileLink && isUserServiceProvider(user)"
  >
    <b-tooltip
      :target="user.id.toString()"
      placement="top"
      triggers="click"
    >
      {{ $t('common.link_copied') }}
    </b-tooltip>
    <b-button
      v-if="short"
      :id="user.id"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="copyServiceProviderProfileLink()"
    >
      <feather-icon
        icon="Share2Icon"
      />
    </b-button>
    <b-button
      v-else
      :id="user.id"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      block
      @click="copyServiceProviderProfileLink()"
    >
      <feather-icon
        icon="Share2Icon"
        class="mr-50"
      />
      <span
        class="align-middle"
      >
        {{ $t('common.share') }}
      </span>
    </b-button>

  </div>

</template>

<script>
import {
  BTooltip,
  BButton,

  VBTooltip,

} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useUserUi } from '@/views/apps/user/useUser'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTooltip,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    short: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  methods: {
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    copyServiceProviderProfileLink() {
      navigator.clipboard.writeText(this.serviceProviderProfileLink)
        .then(
          () => {
            this.showTooltip(this.user.id.toString())
            setTimeout(code => {
              this.hideTooltip(code)
            }, 1000)
          },
          () => {
            /* clipboard write failed */
          },
        )
    },
  },
  setup(props) {
    let serviceProviderProfileLink = ref('')

    const { getServiceProviderProfileLink, isUserServiceProvider } = useUserUi()

    serviceProviderProfileLink = getServiceProviderProfileLink(props.user.id)

    return {
      serviceProviderProfileLink,
      isUserServiceProvider,
    }
  },
}

</script>
<style scoped>

</style>
