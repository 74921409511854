import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import user from './user'
import account from './account'
import notification from './notification'
import location from './location'
import calendar from './calendar'
import app from './app'
import appConfig from './app-config'
import appOptions from './app-options'
import point from './point'
import proposal from './proposal'
import referral from './referral'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    account,
    notification,
    location,
    calendar,
    app,
    appConfig,
    appOptions,
    point,
    proposal,
    referral,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
