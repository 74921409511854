<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title v-if="title">
        <div class="d-flex">
          <feather-icon
            icon="FileTextIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Overview') }}
          </h4>
        </div>
      </b-card-title>
      <b-badge
        pill
        variant="light-secondary"
      >
        <feather-icon
          icon="GlobeIcon"
          class="mr-25"
        />
        <small>
          {{ getLang(pointData.lang) }}
        </small>
      </b-badge>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <b-row>
        <b-col
          cols="12"
        />
        <b-col>
          <offer-purposes-input
            :data="pointData"
          />
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('Title')"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Title')"
              rules="required|max:150"
            >
              <b-form-textarea
                id="title"
                v-model="pointData.name"
                :state="errors.length > 0 ? false:null"
                rows="1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
              rules="max:1000"
            >
              <b-form-textarea
                id="description"
                v-model="pointData.description"
                rows="6"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min,
} from '@validations'
import vSelect from 'vue-select'
import { locales, serviceOptions } from '@/mixins/options'
import { ref } from '@vue/composition-api'

import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { useUserUi } from '@/views/apps/user/useUser'

import OfferPurposesInput from '@/views/apps/service/offer/edit/OfferPurposesInput.vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BBadge,
    OfferPurposesInput,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      pointData: this.data,
      pointPurposes: [],
      required,
      confirmed,
      min,
    }
  },

  mounted() {
    this.$watch('data.id', () => {
      this.pointData = this.data
      if (this.pointData.lang) {
        this.$i18n.locale = this.pointData.lang
      }
    })
  },
  methods: {

  },

  setup() {
    const optionsPointPurposes = ref([])
    const fetchingPointPurposes = ref(false)

    const {
      getLang,
    } = locales()

    const {
      getTypeTitle, getTranslatedPointPurposes,
    } = serviceOptions

    const tagValidator = tag => tag === tag.toLowerCase() && tag.length > 2 && tag.length < 16

    const ability = defineAbilityForCurrentUser()

    const { isManagedUser } = useUserUi()
    return {
      getTranslatedPointPurposes,
      optionsPointPurposes,

      fetchingPointPurposes,
      getTypeTitle,
      getLang,
      tagValidator,
      ability,
      isManagedUser,

    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
