<template>

  <app-timeline>
    <app-timeline-item
      v-if="location"
      icon="SmartphoneIcon"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
        <h6>{{ $t('common.scan_the_qr_code') }}</h6>
      </div>
      <p>{{ $t('point.the_request_form_will_be_opened') }}</p>

      <location-q-rcode :location="location" />

    </app-timeline-item>

    <app-timeline-item
      :title="`${$t('point.enter_the_name_of_the_service_you_need')}`"
      :subtitle="`${getPurposeTitle('plumber')}, ${getPurposeTitle('electrician')}, ${getPurposeTitle('cleaning_of_apartments_and_houses')}...`"
      icon="FileTextIcon"
      time=""
    />

    <app-timeline-item
      :title="`${$t('point.briefly_describe_the_service_required')}`"
      :subtitle="`${$t('Example:')} ${$t('The refrigerator does not freeze')}`"
      icon="FileTextIcon"
      time=""
    />

    <app-timeline-item
      v-if="!location"
      :title="`${$t('location.specify_location')}`"
      subtitle=""
      icon="MapPinIcon"
      time=""
    />
    <app-timeline-item
      v-if="!userId"
      :title="`${$t('common.specify_your_name_and_contact_phone_number')}`"
      :subtitle="userId"
      icon="UserIcon"
      time=""
    />
    <app-timeline-item
      :title="`${$t('common.press_the_button')} '${$t('Add Request')}'`"
      subtitle=""
      icon="CheckCircleIcon"
      time=""
      variant="success"
    />
    <app-timeline-item
      :title="`${$t('common.our_manager_or_the_service_provider_directly_will_contact_you_shortly')}`"
      subtitle=""
      icon="ClockIcon"
      time=""
      variant="secondary"
    />
  </app-timeline>

</template>

<script>

import Ripple from 'vue-ripple-directive'
import LocationQRcode from '@/views/apps/location/details/LocationQRcode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { serviceOptions } from '@/mixins/options'

export default {
  components: {
    LocationQRcode,
    AppTimelineItem,
    AppTimeline,

  },
  directives: {
    Ripple,
  },

  props: {
    location: {
      type: Object,
      required: false,
      default: () => {},
    },
    userId: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
    }
  },

  methods: {

  },
  setup() {
    const { getPurposeTitle } = serviceOptions
    return {
      getPurposeTitle,
    }
  },
}

</script>
