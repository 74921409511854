<template>
  <div>
    <div v-if="isUserProcessed(user)">
      <b-tooltip
        :target="getElId(user.id)"
        placement="top"
      >
        {{ $t('user.user_data_checked_by_manager') }} {{ isUserProcessed(user) }}
      </b-tooltip>
      <b-button
        :id="getElId(user.id)"
        variant="outline-success"
        class="btn-icon rounded-circle"
        @click="displayTooltip()"
      >
        <feather-icon
          icon="UserCheckIcon"
        />

      </b-button>
    </div>
    <div v-else>
      <b-tooltip
        :target="getElId(user.id)"
        placement="top"
      >
        {{ $t('user.user_data_unchecked_by_manager') }}
      </b-tooltip>
      <b-button
        :id="getElId(user.id)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-secondary"
        class="btn-icon rounded-circle"
        @click="displayTooltip()"
      >
        <feather-icon
          icon="UserIcon"
        />

      </b-button>
    </div>

  </div>

</template>

<script>
import {
  BTooltip,
  BButton,

  VBTooltip,

} from 'bootstrap-vue'
import { useUserUi } from '@/views/apps/user/useUser'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTooltip,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    getElId(id) {
      return 'processed-'.concat(id.toString())
    },
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    displayTooltip() {
      this.showTooltip(this.getElId(this.user.id))
      setTimeout(code => {
        this.hideTooltip(code)
      }, 1000)
    },
  },
  setup(props) {
    const { isUserProcessed } = useUserUi()

    return {
      isUserProcessed,
    }
  },
}

</script>
<style scoped>

</style>
