<template>
  <b-row>
    <b-col
      md="8"
      offset-sm="2"
    >
      <!-- alert -->
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          {{ $t('The notification permission is required') }}
        </h4>
        <div class="alert-body">
          <p>{{ $t('Please provide notification permission') }}</p>
        </div>
      </b-alert>
      <b-alert
        v-if="notificationsPermissions==='denied'"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          {{ $t('Permission has been blocked as you ignore the permission prompt several times or denied permission') }}
        </h4>
        <div class="alert-body">
          <p>{{ $t('This can be reset in Page Info which can be accessed by clicking the lock icon next to the URL') }}

          </p><p>
            {{ $t('See for more information:') }}
            <b-link
              href="https://support.google.com/chrome/answer/3220216"
              target="_blank"
              class="alert-link"
            > https://support.google.com/chrome/answer/3220216</b-link>) }}
          </p>
        </div>
      </b-alert>
      <!--/ alert -->
      <div class="text-center"><b-button
        variant="primary"
        :disabled="notificationsPermissions==='denied'"
        @click="requestPermission"
      >
        <feather-icon
          icon="BellIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Provide Permission') }}</span>

      </b-button>
      </div></b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/mixins/notification/toasts'
import {
  pushNotification,
} from '@/views/apps/notification/useNotification'
import { watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,

  },
  directives: {
    Ripple,
  },
  mixins: [showToast],

  data() {
    return {
      appInstalled: false,
    }
  },

  computed: {

  },
  // watch: {
  //   notificationsPermissions(value) {
  //     if (value === 'granted') {
  //
  //     }
  //   },
  // },
  created() {
    // window.addEventListener('appinstalled', () => {
    //   this.appInstalled = true
    // })

  },

  setup() {
    const {
      notificationsSupported,
      notificationsEnabled,
      requestPermission,
      notificationsPermissions,
    } = pushNotification()

    watch(notificationsEnabled, val => {
      store.commit('notification/SET_NOTIFICATIONS_ENABLED', val)
    }, { immediate: true })
    return {
      notificationsSupported,
      notificationsEnabled,
      requestPermission,
      notificationsPermissions,
    }
  },
}
</script>
<style scoped>
#notifications .col-md-6,
#notifications .col-md-4{
  max-width: 100%;
  flex-grow: 1;
}

</style>
