<template>
  <div>
    <div
      v-if="layout==='buttons'"
      class="d-flex justify-content-between align-items-center my-1 "
    >
      <div
        v-for="locale in localesList"
        :key="locale.locale"
        @click="setLocale(locale.locale)"
      >
        <!--      <b-avatar-->

        <!--        :variant="`${$i18n.locale===locale.label?'warning':'primary'}`"-->
        <!--        class="lang"-->
        <!--        @click="setLocale(locale.locale)"-->
        <!--      >-->
        <!--        <span class="text-uppercase">{{ locale.name }}</span>-->
        <!--      </b-avatar>-->
        <b-button
          class="btn-icon"
          :variant="`outline-${$i18n.locale===locale.label?'warning':'primary'}`"
          @click="setLocale(locale.locale)"
        >
          <span>{{ locale.name }}</span>
        </b-button>
      </div>

    </div>
    <b-nav-item-dropdown
      v-if="layout==='dropdown'"
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template #button-content>
        <!--      <b-img-->
        <!--        :src="currentLocale.img"-->
        <!--        height="14px"-->
        <!--        width="22px"-->
        <!--        :alt="currentLocale.locale"-->
        <!--      />-->
        <span class="text-body">{{ currentLocale.name }}</span>
      </template>
      <b-dropdown-item
        v-for="localeObj in localesList"
        :key="localeObj.locale"
        @click="setLocale(localeObj.locale)"
      >
        <!--      <b-img-->
        <!--        :src="localeObj.img"-->
        <!--        height="14px"-->
        <!--        width="22px"-->
        <!--        :alt="localeObj.locale"-->
        <!--      />-->
        <span class="text-body">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>

</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BImg, BButton,
} from 'bootstrap-vue'
import { locales } from '@/mixins/options'
import { localize } from 'vee-validate'
import uk from 'vee-validate/dist/locale/uk.json'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'
import ru from 'vee-validate/dist/locale/ru.json'
import i18n from '@/libs/i18n'

localize({
  en,
  uk,
  de,
  ru,
})
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BButton,
  },
  props: {
    layout: {
      type: String,
      required: false,
      default: () => 'dropdown',
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.localesList.find(l => l.locale === i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const { localesList, setLocale } = locales()
    /* eslint-disable global-require */
    localize(i18n.locale)
    return {
      localesList,
      setLocale,
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu{
  min-width: 1rem;
}
.btn-icon{
  margin: 0.2rem;
}
</style>
