<template>
  <div>
    <!--    <b-form-rating-->
    <!--      id="rating"-->
    <!--      no-border-->
    <!--      value="5"-->
    <!--      readonly-->
    <!--      variant="warning"-->
    <!--      inline-->
    <!--      size="lg"-->
    <!--    />-->
  </div>
</template>

<script>
import { BFormRating } from 'bootstrap-vue'

export default {
  components: {
    BFormRating,
  },
}
</script>
<style lang="scss" scoped >
.b-rating {
  background-color: unset;
  padding: 0;
}
</style>
