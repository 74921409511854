export default [
  // {
  //   path: '/add-route',
  //   name: 'add-route',
  //   component: () => import('@/views/pages/routes/AddRoute.vue'),
  //   meta: {
  //     resource: 'Routes',
  //     action: 'read',
  //     pageTitle: 'Add Route',
  //     breadcrumb: [
  //       {
  //         text: 'Add Route',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/uk/privacy-policy',
    name: 'privacy-policy-uk',
    component: () => import('@/views/pages/miscellaneous/PrivacyPolicy-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/en/privacy-policy',
    name: 'privacy-policy-en',
    component: () => import('@/views/pages/miscellaneous/PrivacyPolicy-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/de/privacy-policy',
    name: 'privacy-policy-de',
    component: () => import('@/views/pages/miscellaneous/PrivacyPolicy-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/ru/privacy-policy',
    name: 'privacy-policy-ru',
    component: () => import('@/views/pages/miscellaneous/PrivacyPolicy-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/uk/public-offer',
    name: 'public-offer-uk',
    component: () => import('@/views/pages/miscellaneous/PublicOffer-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/en/public-offer',
    name: 'public-offer-en',
    component: () => import('@/views/pages/miscellaneous/PublicOffer-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/de/public-offer',
    name: 'public-offer-de',
    component: () => import('@/views/pages/miscellaneous/PublicOffer-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/ru/public-offer',
    name: 'public-offer-ru',
    component: () => import('@/views/pages/miscellaneous/PublicOffer-UK.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPasswordEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'Account',
      action: 'update',
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/provider/:id',
    name: 'pages-profile-service-provider',
    component: () => import('@/views/pages/profile/ServiceProviderProfile.vue'),
    meta: {
      resource: 'Auth',
      pageTitle: 'Contractor',
      breadcrumb: [
        {
          text: 'List',
          // to: { name: 'apps-user-service-providers-list' },
        },
      ],
    },
  },

  {
    path: '/pages/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      resource: 'Account',
      action: 'update',
      pageTitle: 'Account',

    },
  },

  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      resource: 'Auth',
      pageTitle: 'FAQ\'s',
    },
  },
  {
    path: '/pages/knowledge-base',
    name: 'pages-knowledge-base',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBase.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'pages-knowledge-base-category',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base',
        },
        {
          text: 'Category',
          active: true,
        },
      ],
      navActiveLink: 'pages-knowledge-base',
    },
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'pages-knowledge-base-question',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base',
        },
        {
          text: 'Category',
          to: '/pages/Knowledge-base/category',
        },
        {
          text: 'Question',
          active: true,
        },
      ],
      navActiveLink: 'pages-knowledge-base',
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pages/landing/LandingPricing.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
    },
  },
  // {
  //   path: '/pages/pricing',
  //   name: 'pages-pricing',
  //   component: () => import('@/views/pages/pricing/Pricing.vue'),
  // },
  // {
  //   path: '/pages/blog/list',
  //   name: 'pages-blog-list',
  //   component: () => import('@/views/pages/blog/BlogList.vue'),
  //   meta: {
  //     pageTitle: 'Blog List',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Blog',
  //       },
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/blog/:id',
  //   name: 'pages-blog-detail',
  //   component: () => import('@/views/pages/blog/BlogDetail.vue'),
  //   meta: {
  //     pageTitle: 'Blog Detail',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Blog',
  //       },
  //       {
  //         text: 'Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/blog/edit/:id',
  //   name: 'pages-blog-edit',
  //   component: () => import('@/views/pages/blog/BlogEdit.vue'),
  //   meta: {
  //     pageTitle: 'Blog Edit',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Blog',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/',
    name: 'pages-landing',
    component: () => import('@/views/apps/service/offer/list/List.vue'),
    meta: {
      resource: 'Auth',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/promo',
    name: 'promo',
    component: () => import('@/views/pages/promo/Promo.vue'),
    meta: {
      pageTitle: 'Promo',
      resource: 'Referral',
      action: 'update',
    },
  },
  {
    path: '/promo/referrals',
    name: 'promo-referrals',
    component: () => import('@/views/pages/promo/PromoReferrals.vue'),
    meta: {
      pageTitle: 'referral.referrals',
      resource: 'Referral',
      action: 'update',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'referral.referral_links',
          to: '/promo/referral/links',
        },
      ],
    },
  },
  {
    path: '/promo/referral/links',
    name: 'promo-referral-links',
    component: () => import('@/views/pages/promo/PromoReferralLinks.vue'),
    meta: {
      pageTitle: 'referral.referral_links',
      resource: 'Referral',
      action: 'update',
      icon: 'MessageSquareIcon',
      breadcrumb: [
        {
          text: 'referral.referrals',
          to: '/promo/referrals',
        },
      ],
    },
  },
]
