import axios from '@axios'
import Location from '@/mixins/classes/location'

export default {
  namespaced: true,
  state: {
    location: {},
    locations: [],
  },
  getters: {
    location: state => state.location,
    Location: state => new Location(state.location),
    locations: state => state.locations,
  },
  mutations: {
    setLocation(state, location) {
      state.location = location
    },
    deleteLocation(state) {
      state.location = {}
    },
    setLocations(state, locations) {
      state.locations = locations
    },
  },
  actions: {

    async fetchLocations(ctx, queryParams) {
      const response = await axios.get('/addresses', {
        params: queryParams,
      }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setLocations', response.data.data)
      }
      return response
    },
    async fetchLocation(ctx, { id }) {
      const response = await axios.get(`/addresses/${id}`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setLocation', response.data.data)
      }
      return response
    },

    async fetchPublicLocation(ctx, { id }) {
      const response = await axios.get(`/public/addresses/${id}`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setLocation', response.data.data)
      }
      return response
    },

    async addLocation(ctx, location) {
      const response = await axios.post('/addresses', location).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setLocation', response.data.data)
      }
      return response
    },

    async addLocationLookup(ctx, location) {
      const response = await axios.post('/addresses?lookup', location).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setLocation', response.data.data)
      }
      return response
    },

    async updateLocation(ctx, location) {
      const response = await axios.patch(`/addresses/${location.id}`, location).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setLocation', response.data.data)
        response.statusText = 'updated'
      }
      return response
    },

    async deleteLocation(ctx, { id }) {
      const response = await axios.delete(`/addresses/${id}`).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
  },
}
