import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { serviceOptions } from '@/mixins/options'
import { ref } from '@vue/composition-api'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('app-ecommerce/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('app-ecommerce/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch('app-ecommerce/addProductInCart', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return store.dispatch('app-ecommerce/removeProductFromCart', { productId })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  }
}

export const useEventsUI = () => {
  const getOptionPurposes = options => {
    // Add icons, tooltips etc
    const result = []
    options.forEach(obj => {
      const purpose = serviceOptions.purposes.find(({ slug }) => slug === obj.slug)
      result.push({ ...purpose, ...obj })
    })
    return result
  }

  const currentUserIsEventOrganizer = e => (store.getters['account/user'].id === e.extendedProps.organizer_id) || ((store.getters['calendar/eventOrganizer'].id === e.extendedProps.organizer_id) && (store.getters['calendar/eventOrganizer'].managed))

  const userCanUpdateRequest = e => {
    const ability = defineAbilityForCurrentUser()
    const point = store.getters['point/Point']
    return ability.can('manage', point) || ability.can('update', point)
  }
  const requestPublished = e => e.extendedProps.post_status === 'published'
  const eventDateIsActual = e => new Date().getTime() < new Date(e.extendedProps.appointment).getTime()
  const eventNew = e => !('id' in e)
  const eventDeclined = e => e.extendedProps.decline_date !== null
  const eventWithdrawn = e => e.extendedProps.withdraw_date !== null
  const eventAccepted = e => e.extendedProps.accept_date !== null && !eventDeclined(e) && !eventWithdrawn(e)
  const eventCanBeWithdrawn = e => eventAccepted(e) && currentUserIsEventOrganizer(e) && !eventWithdrawn(e) && requestPublished(e)
  const eventCanBeDeclined = e => !eventDeclined(e) && !eventNew(e) && !currentUserIsEventOrganizer(e) && eventAccepted(e) && !eventWithdrawn(e) && requestPublished(e)
  const eventCanBeAccepted = e => !eventDeclined(e) && !eventNew(e) && !currentUserIsEventOrganizer(e) && !eventAccepted(e) && !eventWithdrawn(e) && requestPublished(e)
  const eventIsActual = e => !eventDeclined(e) && !eventWithdrawn(e) && requestPublished(e)
  const eventCanBeUpdated = e => !eventAccepted(e) && currentUserIsEventOrganizer(e) && eventIsActual(e)
  const eventCanBeDeleted = e => !eventAccepted(e) && !eventDeclined(e) && !eventWithdrawn(e) && currentUserIsEventOrganizer(e)

  // const proposalCancelable = proposal => {
  //   if (proposal.complete_date) {
  //     return false
  //   }
  //   if (proposal.accept_date && proposal.decline_date === null) {
  //     return true
  //   }
  //   if (proposal.decline_date && proposal.accept_date) {
  //     const dd = new Date(proposal.decline_date)
  //     const ad = new Date(proposal.accept_date)
  //     return ad > dd
  //   }
  //
  //   return false
  // }

  const userPhones = proposal => {
    if (typeof proposal.organizer.phones !== 'undefined' && proposal.organizer.phones.length > 0) {
      return proposal.organizer.phones
    }
    return []
  }

  const { router } = useRouter()

  const resolvePointTypeBadgeVariant = pointTypeSlug => (pointTypeSlug === 'offer' ? 'primary' : 'secondary')

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = product => {
    const { addProductInCart } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInCart = true

        // Update cart items count
        store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
      })
    }
  }

  const handleEditOfferActionClick = product => {
    router.push({ name: 'apps-service-offer-edit', params: { id: product.id } })
  }
  const handleEditRequestActionClick = product => {
    router.push({ name: 'apps-service-request-edit', params: { id: product.id } })
  }

  const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const { addProductInCart, removeProductFromWishlist } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
          removeProductFromWishlist(product.id)

          // Update cart items count
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInWishlist = false
          removeProductFromWishlistUi(product)
        })
    }
  }
  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleEditOfferActionClick,
    handleEditRequestActionClick,
    handleWishlistCartActionClick,
    resolvePointTypeBadgeVariant,
    getOptionPurposes,
    eventNew,
    eventWithdrawn,
    eventDeclined,
    eventAccepted,
    currentUserIsEventOrganizer,
    eventIsActual,
    eventCanBeUpdated,
    eventCanBeWithdrawn,
    eventCanBeDeclined,
    eventCanBeAccepted,
    eventDateIsActual,
    eventCanBeDeleted,
    userPhones,
  }
}
export const manageProposalActions = () => {
  const { router } = useRouter()
  const { eventAccepted, currentUserIsEventOrganizer, eventIsActual } = useEventsUI()
  const proposalCanBeAdded = (proposals, point) => {
    if (point.post_status !== 'published') {
      return false
    }

    if (typeof proposals === 'undefined' || proposals.length < 0) {
      return true
    }
    const currentUserProposals = proposals.filter(proposal => currentUserIsEventOrganizer(proposal))
    if (!currentUserProposals) {
      return true
    }

    return currentUserProposals.findIndex(proposal => eventIsActual(proposal)) < 0
  }
  const acceptedProposal = ref(null)
  const getAcceptedProposal = proposals => {
    acceptedProposal.value = proposals.filter(proposal => eventAccepted(proposal))
    return acceptedProposal.value ? acceptedProposal.value[0] : []
  }
  const handleAddProposalActionClick = (request, organiser) => {
    const eventOrganizer = organiser || store.getters['account/user']
    store.commit('calendar/setOrganizer', eventOrganizer)
    store.commit('calendar/setData', request)
    router.push({ name: 'event-calendar-add', params: { id: request.id } })
  }

  const handleEditProposalActionClick = event => {
    router.push({ name: 'event-calendar-edit', params: { id: event.id } })
  }

  return {
    acceptedProposal,
    getAcceptedProposal,
    proposalCanBeAdded,
    handleAddProposalActionClick,
    handleEditProposalActionClick,
  }
}

export const useProposalRemoteData = () => {
  const proposals = ref({})
  const fetchProposals = (...args) => store.dispatch('proposal/fetchProposals', ...args)
  const acceptProposal = proposalId => store.dispatch('proposal/acceptProposal', { id: proposalId })
  const declineProposal = (...args) => store.dispatch('proposal/declineProposal', ...args)
  const withdrawProposal = (...args) => store.dispatch('proposal/withdrawProposal', ...args)
  const deleteProposal = (...args) => store.dispatch('proposal/deleteProposal', ...args)
  const fetchMyProposals = (...args) => store.dispatch('proposal/fetchMyRequestProposals', ...args)

  return {
    proposals,
    acceptProposal,
    declineProposal,
    withdrawProposal,
    deleteProposal,
    fetchProposals,
    fetchMyProposals,
  }
}
