var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[(_vm.title)?_c('b-card-title',[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"mb-0 ml-50"})])]):_vm._e()],1),_c('b-card-body',{staticClass:"align-items-center"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('b-form-radio',{staticClass:"custom-control-primary mb-1",attrs:{"name":"ownRequest","value":true},model:{value:(_vm.ownRequest),callback:function ($$v) {_vm.ownRequest=$$v},expression:"ownRequest"}},[_vm._v(" "+_vm._s(_vm.$t('point.own_request'))+" ")]),_c('b-form-radio',{staticClass:"custom-control-warning mb-1",attrs:{"name":"ownRequest","value":false},model:{value:(_vm.ownRequest),callback:function ($$v) {_vm.ownRequest=$$v},expression:"ownRequest"}},[_vm._v(" "+_vm._s(_vm.$t('point.request_on_behalf'))+" ")]),(!_vm.ownRequest)?_c('div',{staticClass:"mt-sm-2 mt-md-4"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('point.user_name'),"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"userName",attrs:{"id":"userName","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('common.John Doe')},model:{value:(_vm.pointData.user_name),callback:function ($$v) {_vm.$set(_vm.pointData, "user_name", $$v)},expression:"pointData.user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2494495563)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('point.user_phone'),"vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"phone","error":_vm.phone.valid!==true,"name":"phone","valid-color":"#28C76F","required":true,"translations":{
                    countrySelectorLabel: _vm.$t('Country code'),
                    countrySelectorError: _vm.$t('Choose a country'),
                    phoneNumberLabel: _vm.$t('Phone Number'),
                    example: _vm.$t('Example:')
                  },"only-countries":_vm.countriesList,"default-country-code":_vm.countryCode},on:{"update":_vm.onPhoneUpdate},model:{value:(_vm.phone.number),callback:function ($$v) {_vm.$set(_vm.phone, "number", $$v)},expression:"phone.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1857962935)})],1),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"checked":"true","name":"managed-request","switch":"","inline":""},model:{value:(_vm.pointData.managed),callback:function ($$v) {_vm.$set(_vm.pointData, "managed", $$v)},expression:"pointData.managed"}},[_vm._v(" "+_vm._s(_vm.$t('point.managed_request'))+" ")]),(_vm.pointData.managed)?_c('p',{staticClass:"mt-1 small"},[_vm._v(" "+_vm._s(_vm.$t('point.managed_request_desc'))+" ")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }