import { render, staticRenderFns } from "./ListFilterPurposes.vue?vue&type=template&id=1f09dcfa&scoped=true&"
import script from "./ListFilterPurposes.vue?vue&type=script&lang=js&"
export * from "./ListFilterPurposes.vue?vue&type=script&lang=js&"
import style0 from "./ListFilterPurposes.vue?vue&type=style&index=0&id=1f09dcfa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f09dcfa",
  null
  
)

export default component.exports