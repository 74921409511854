<template>
  <app-collapse
    class="mt-1"
    type="margin"
  >
    <app-collapse-item
      title="Filters"
      :is-visible="true"
    >
      <template #header>
        <div
          class="d-flex justify-content-between align-items-center text-primary h5 text-uppercase"
        >
          <feather-icon
            icon="BriefcaseIcon"
            size="22"
            class="mr-1 d-none d-lg-block"
          />
          <span>{{ $t('service_list') }} </span>
        </div>
      </template>
      <b-row>
        <b-col>
          <b-overlay
            :show="submittingFetch"
            rounded
            opacity="0.6"
            spinner-medium
            spinner-variant="primary"
            spinner-type="grow"
          >
            <b-row v-if="options">
              <b-col
                class="text-center pb-1"
              >
                <div
                  v-if="!collapsed"
                  class="text-columns text-left"
                >
                  <div
                    v-for="option in options.pointPurposes"
                    :key="option.id"
                    class="pb-2"
                  >
                    <div class="pb-1 pl-1 h5 text-uppercase">
                      {{ option.title }}
                    </div>

                    <p
                      v-for="purpose in getTranslatedPointPurposes(option.point_purposes)"
                      :key="option.id+purpose.id"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-left cursor-pointer pl-1 pb-1"
                      :class="getClass(purpose.id)"
                      @click="()=>selectPurpose(option.id, [purpose.id])"
                    >
                      {{ purpose.title }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <app-collapse type="default">

                    <app-collapse-item
                      v-for="option in options.pointPurposes"
                      :key="option.id"
                      :title="getTypeTitle(option.slug)"
                    >
                      <p
                        v-for="purpose in getTranslatedPointPurposes(option.point_purposes)"
                        :key="option.id+purpose.id"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="text-left cursor-pointer pl-1 pb-1"
                        :class="getClass(purpose.id)"
                        @click="()=>selectPurpose(option.id, [purpose.id])"
                      >
                        {{ purpose.title }}
                      </p>
                    </app-collapse-item>

                  </app-collapse>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'

import store from '@/store'
import { serviceOptions } from '@/mixins/options'
import { ref } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { useServicesQueryHandler } from '@/views/apps/service/usePoints'
import { scrollToTop } from '@/mixins/functions'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BOverlay,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {

    }
  },
  watch: {
    '$i18n.locale': function () {
      this.fetchPointTypesAndPurposes()
    },
  },

  setup() {
    const options = ref({})
    const submittingFetch = ref(false)
    const {
      getTranslatedPointTypes, getTranslatedPointPurposes, getTypeTitle,
    } = serviceOptions

    const {
      getQuery, removeQuery,
    } = useServicesQueryHandler()

    const updateOptions = val => {
      options.value.pointPurposes = getTranslatedPointTypes(val)
    }

    const fetchPointTypesAndPurposes = () => {
      submittingFetch.value = true
      store.dispatch('point/getPointTypesAndPurposes')
        .then(response => {
          if (response.data) {
            updateOptions(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            options.value.pointPurposes = undefined
          }
        })
        .finally(() => {
          submittingFetch.value = false
        })
    }
    fetchPointTypesAndPurposes()

    const handleQuery = () => {
      if (getQuery()) {
        store.commit('point/setFilter', getQuery())
        removeQuery()
      }
    }

    const selectPurpose = (typeId, purposeIds) => {
      store.commit('point/setFilter', { point_type: typeId, point_purposes: purposeIds })
      scrollToTop()
    }

    handleQuery()

    const getClass = purposeId => (('point_purposes' in store.state.point.filter) && store.state.point.filter.point_purposes.find(el => el === purposeId) ? 'text-bold' : '')

    return {
      submittingFetch,
      getTranslatedPointPurposes,
      getTypeTitle,
      selectPurpose,
      options,
      fetchPointTypesAndPurposes,
      handleQuery,
      getClass,
    }
  },
}
</script>
<style lang="scss" scoped>
.text-bold{
  font-weight: bold;
}
.collapse-margin .card {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none !important;
  border-radius: 0.358rem;
  border-bottom: 1px solid #ebe9f1!important;
}

</style>
