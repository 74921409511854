<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-flex d-lg-none mr-1"
                size="22"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex justify-content-start align-items-center ">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=" text-uppercase"
                block
                @click="$router.push({ name: 'apps-service-request-add-wizard'})"
              >
                <span class="d-none d-sm-block">{{ $t('Request services') }}</span>
                <span class="d-sm-none">{{ $t('Add') }}</span>

              </b-button>
              <!-- Per Page -->
              <!--              <b-col>-->
              <!--                <label>Show</label>-->
              <!--                <v-select-->
              <!--                  v-model="filters.perPage"-->
              <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                  :options="filterOptions.perPage"-->
              <!--                  :clearable="false"-->
              <!--                  class="per-page-selector d-inline-block mx-50"-->
              <!--                />-->
              <!--                <label>entries</label>-->
              <!--              </b-col>-->
              <!-- Sort Button -->
              <!--              <b-dropdown-->
              <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                :text="$t(sortBy.text)"-->
              <!--                right-->
              <!--                variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item-->
              <!--                  v-for="sortOption in sortByOptions"-->
              <!--                  :key="sortOption.value"-->
              <!--                  @click="sortBy=sortOption"-->
              <!--                >-->
              <!--                  {{ $t(sortOption.text) }}-->
              <!--                </b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <div
              class="d-flex justify-content-start align-items-center "
            >
              <span v-if="totalPoints!==null">
                {{ $tc("requests found", totalPoints) }}
              </span>
              <span v-else>{{ $t('point.request_finder') }}</span>
            </div>
            <b-input-group
              id="search"
              class="input-group-merge"
            >
              <b-input-group-prepend is-text>
                <b-spinner
                  v-if="submittingFetch"
                  small
                  type="grow"
                  variant="secondary"
                />
                <feather-icon
                  v-else
                  icon="SearchIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="q"
                :placeholder="$t('Search')"
                class="search-point"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <!-- Points -->
      <section :class="itemView">
        <b-card
          v-for="point in points"
          :key="point.id"
          class="ecommerce-card no-image"
        >
          <b-card-text>
            <div class="position-absolute statuses">
              <b-badge
                v-if="point.receiver"
                variant="light-warning"
              >
                <small class="text-nowrap">
                  {{ $t('point.direct_request') }}
                  <feather-icon
                    icon="BookmarkIcon"
                  />
                </small>
              </b-badge>
              <b-badge
                :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"
              >
                <small>
                  {{ $t(point.post_status) }}
                  <feather-icon
                    :icon="getPostStatusProperty(point.post_status, 'icon')"
                  />
                </small>
              </b-badge>
            </div>
          </b-card-text>

          <router-link
            v-if="itemView==='grid-view' "
            class="item-img text-center pb-1"
            :to="{ name: 'apps-service-request-info', params: { id: point.id } }"
          >
            <b-img-lazy
              :alt="`${point.name}-${point.id}`"
              fluid
              class="card-img-top"
              :src="getFirstGalleryImage(point)"
              @error.native="$event.target.src=defaultImage"
            />
          </router-link>

          <!-- Product Details -->
          <b-card-text
            class="link"
            @click="$router.push({name: 'apps-service-request-info', params: { id: point.id }})"
          >
            <b-card-text class="text-left">
              <b-badge
                pill
                :variant="getOwnerProperty(point).class"
              >
                <feather-icon
                  :icon="getOwnerProperty(point).icon"
                />
                <small>
                  # {{ point.id }}
                </small>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
                />
                <span>
                  {{ point.number_of_views }}
                </span>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <feather-icon
                  icon="ThumbsUpIcon"
                  class="mr-25"
                />
                <span>
                  {{ point.events.length.toString() }}
                </span>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <small class="text-nowrap">
                  <feather-icon
                    v-if="point.create_date!==point.update_date"
                    icon="Edit3Icon"
                    class="mr-25"
                  />
                  <feather-icon
                    v-else
                    icon="Edit2Icon"
                    class="mr-25"
                  />
                  {{ point.update_date | formatDateTime() }}
                </small>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <small>
                  {{ getTypeTitle(point.point_type.slug) }}
                </small>
              </b-badge>
            </b-card-text>
            <h5>
              {{ point.name }}
            <!--            <b-card-text class="item-company">-->
            <!--              By <b-link class="ml-25">-->
            <!--                {{ point.brand }}-->
            <!--              </b-link>-->
            <!--            </b-card-text>-->
            </h5>
            <b-card-text class="item-description">
              {{ point.description }}
            </b-card-text>
          </b-card-text>

          <!-- Product Actions -->
          <!--          <div-->
          <!--            v-if="point.post_status==='published'"-->
          <!--            class="item-options text-center"-->
          <!--          >-->
          <!--            <div />-->

          <!--            <div class="item-wrapper">-->
          <!--            <div class="item-cost">-->
          <!--              <h4 class="item-price">-->
          <!--                ${{ point.price }}-->
          <!--              </h4>-->
          <!--            </div>-->
          <!--            </div>-->
          <!--          <b-button-->
          <!--            variant="light"-->
          <!--            tag="a"-->
          <!--            class="btn-wishlist"-->
          <!--            @click="toggleProductInWishlist(point)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="HeartIcon"-->
          <!--              class="mr-50"-->
          <!--              :class="{'text-danger': point.isInWishlist}"-->
          <!--            />-->
          <!--            <span>Wishlist</span>-->
          <!--          </b-button>-->
          <!--          <b-button-->
          <!--            variant="primary"-->
          <!--            tag="a"-->
          <!--            class="btn-wishlist"-->
          <!--            @click="handleEditRequestActionClick(point)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="EditIcon"-->
          <!--              class="mr-50"-->
          <!--            />-->
          <!--            <span>{{ $t('Edit') }}</span>-->
          <!--          </b-button>-->
          <!--          </div>-->
          <b-card-text
            v-if="ability.cannot('update', point)"
            class="position-absolute author"
          >
            <em><small class="mr-25 text-secondary">{{ $t('Author') }}: {{ point.user.name }}</small></em>
            <b-avatar
              size="24"
              variant="light-secondary"
              :src="point.user.avatar"
              :text="getUserAvatarText(point.user.name)"
            />
          </b-card-text>
        </b-card>
        <!-- Tenants -->

      </section>

      <!-- Pagination -->
      <section v-if="totalPoints">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalPoints"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCard,
  BCardText,
  BPagination,
  BBadge,
  BImgLazy,
  BFormGroup,
  BOverlay,
  BAvatar,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Point from '@/mixins/classes/point'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { formatDateTime } from '@/filters/dateTime'
import { serviceOptions } from '@/mixins/options'
import { useDebounceFn } from '@vueuse/core'
import LeftFilterSidebar from './RequestListLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useServiceRequestsRemoteData } from './useServiceRequestsList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BCardText,
    BPagination,
    BBadge,
    BImgLazy,
    BFormGroup,
    BOverlay,
    BButton,
    BAvatar,
    BSpinner,
    // SFC
    LeftFilterSidebar,

  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],
  setup() {
    const q = ref('')
    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      handleCartActionClick,
      toggleProductInWishlist,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,
      getFirstGalleryImage,
      defaultImage,
      getOwnerProperty,
    } = usePointsUi()

    const {
      itemView, itemViewOptions, totalPoints,
    } = useShopUi()

    const ability = defineAbilityForCurrentUser()

    const {
      points, fetchServiceRequests, submittingFetch,
    } = useServiceRequestsRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchServiceRequests` which can be triggered initially and upon changes of filters
    const fetchFilteredPoints = () => {
      const args = {
        q: q.value,
        page: filters.value.page,
        per_page: filters.value.perPage,
        owner: filters.value.postOwner,
      }
      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }

      if (filters.value.userId) {
        args.user_id = filters.value.userId
      }

      if (sortBy.value.value) {
        args.sort_by = sortBy.value.value
      }

      if (filters.value.postStatuses.length !== 0) {
        args.post_statuses = filters.value.postStatuses.join()
      }

      args.order = 'desc'

      fetchServiceRequests(args)
        .then(response => {
          const { total, purposes } = response.data.meta
          points.value = response.data.data.map($item => new Point($item))
          totalPoints.value = total
          filterOptions.pointPurposes.value = purposes
        })
    }

    fetchFilteredPoints()

    watch([filters, sortBy], () => {
      fetchFilteredPoints()
    }, {
      deep: true,
    })

    const onSearchDebounced = useDebounceFn(query => {
      if (!query || query.length > 2) {
        fetchFilteredPoints()
      }
    }, 500)

    watch(q, () => {
      onSearchDebounced(q.value)
    })
    return {
      // useShopFiltersSortingAndPagination
      filters,
      q,
      filterOptions,
      sortBy,
      sortByOptions,
      onSearchDebounced,

      // useShopUi
      itemView,
      itemViewOptions,
      totalPoints,

      // usePointsUi
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,

      getFirstGalleryImage,
      defaultImage,
      getPurposeTitle,
      getTypeTitle,

      getOwnerProperty,

      // useServiceRequestsRemoteData
      submittingFetch,
      points,
      ability,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.author{
  bottom: -1px!important;
  right:0;
}
.statuses{
  top: -1px!important;
  right:0;
}
.statuses>span{
  border-radius: 0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.search-point.form-control{
  height: auto;
  border: none;
}
[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
min-height: 15.85rem;
display: block;
align-items: center;
}
.card-img-top {
max-height: 20em;
object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
background-color: #242B3D !important;
}

.ecommerce-application .grid-view {
  grid-template-columns: none;
}

.link{
cursor: pointer;
}
.ecommerce-application .list-view .ecommerce-card .card-body {
border-right: none;
}
.ecommerce-application .list-view .ecommerce-card{
  margin-bottom: 1rem;
  grid-template-columns: none;
}

@media (min-width: 767.98px) {
.ecommerce-application .list-view .ecommerce-card.no-image,
.ecommerce-application .grid-view{
  //grid-template-columns: 1fr 1fr;
}

}
</style>
